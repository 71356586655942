/*************关于我们 */
const info = {
    cn: {
        headerPicWord: "电动车智能安全停车充电硬件软件提供者",
        headerPicChTitle: "关于我们",
        headerPicEnTitle: "About Us",
        corporateCulture: {
            titleChName: "公司简介",
            titleEnName: "Company Profile",
            infoList: [
                {
                    title: "企业背景",
                    info: "成为优秀卓越的智能化系统服务商"
                },
                {
                    title: "企业使命",
                    info: "坚持技术的引领，模式创新，行业示范"
                },
                {
                    title: "企业宗旨",
                    info: "提供优质智能硬件，让使用更安全、高效"
                },
                {
                    title: "核心价值观",
                    info: "安全、环保、共享、创新便民经济"
                },
            ]
        },
        companyProfile: {
            titleChName: "企业文化",
            titleEnName: "Corporate Culture",
            info: "成都铅笔科技有限公司是一家集嵌入式硬件软件开发、互联网软件开发于一体的国家高新技术企业，提供智能家居，智能安防和智慧社区，智能停车、新能源电单车充电和新能源汽车充电等应用解决方案，掌握智能控制系统的关键核心技术，推出了电动汽车充电桩控制器、电动自行车充电控制器、门禁通道、互联网支付、充电停车运营管理平台、充电桩设备维护管理平台、APP云平台管理等系列产品；公司联合产业链的上下游企业，组成强大的生态圈，为合作伙伴及客户提供一站式服务。公司一直专注于智慧社区、智能家居、智能停车、新能源充电领域的产品设计、技术研发、设备生产、销售以及安装、调试、运行维护等全流程服务。并设有2个核心城市研发运营中心，成都为软件研发和运营中心、深圳为硬件研发生产和全国营销中心，现员工总数40余人，其65%为技术人才，研发团队来自英特尔，华为，中兴，微软、大唐等。凭借强劲的产品创新能力和技术研发实力，铅笔智能致力于成为智慧社区综合管理及新能源电单车和新能源汽车充电领域、综合解决方案提供商。"
        },
        honorsAndQualifications: {
            titleChName: "荣誉资质",
            titleEnName: "Honors and qualifications",
        },
        contactUs: {
            titleChName: "联系我们",
            titleEnName: "Contact us",
            infoList: [
                {
                    title: "400-668-3398",
                    info: "电话",
                    pic: "aboutUsContactPhone.png"
                },
                {
                    title: "info@9qianbi.com",
                    info: "邮箱",
                    pic: "aboutUsContactEmail.png"

                },
                {
                    title: "成都高新区天府软件园",
                    info: "地址",
                    pic: "aboutUsContactAddress.png"
                },
            ]
        }
    },
    en: {
        headerPicChTitle: "About Us",
        headerPicEnTitle: "关于我们",
        headerPicWord: "Electric vehicle intelligent safe parking charging hardware and software provider",
        corporateCulture: {
            titleChName: "Company Profile",
            titleEnName: "公司简介",
            infoList: [
                {
                    title: "Enterprise Background",
                    info: "Become an excellent intelligent system service provide"
                },
                {
                    title: "Corporate Mission",
                    info: "Adhere to the guidance of technology, model innovation, industry demonstration"
                },
                {
                    title: "Purpose of Enterprise",
                    info: "Provide high-quality intelligent hardware, so that the use of safer and more efficient"
                },
                {
                    title: "Core Values",
                    info: "Security, environmental protection, sharing, innovation, convenient economy"
                },
            ]
        },
        companyProfile: {
            titleChName: "Corporate Culture",
            titleEnName: "企业文化",
            info: "Chengdu Pencil Technology Co., Ltd. is a national high-tech enterprise that integrates embedded hardware and software development with internet software development. It provides application solutions for smart homes, intelligent security and smart communities, intelligent parking, new energy bicycle charging, and new energy vehicle charging. It has mastered the key core technologies of intelligent control systems and launched electric vehicle charging pile controllers, electric bicycle charging controllers A series of products, including access control channels, internet payments, charging and parking operation management platforms, charging station equipment maintenance and management platforms, and APP cloud platform management; The company collaborates with upstream and downstream enterprises in the industrial chain to form a strong ecosystem, providing one-stop services for partners and customers. The company has always focused on product design, technology research and development, equipment production, sales, installation, debugging, operation and maintenance in the fields of smart communities, smart homes, smart parking, and new energy charging. And there are two core city R&D and operation centers, with Chengdu serving as the software R&D and operation center and Shenzhen as the hardware R&D and production and national marketing center. The total number of employees is over 40, of which 65% are technical talents. The R&D team comes from Intel, Huawei, ZTE, Microsoft, Datang, etc. With strong product innovation capabilities and technological research and development capabilities, Pencil Intelligence is committed to becoming a comprehensive solution provider in the field of smart community comprehensive management, new energy bicycles, and new energy vehicle charging."
        },
        honorsAndQualifications: {
            titleChName: "Honors and qualifications",
            titleEnName: "荣誉资质",
        },
        contactUs: {
            titleChName: "Contact us",
            titleEnName: "联系我们",
            infoList: [
                {
                    title: "400-668-3398",
                    info: "phone",
                    pic: "aboutUsContactPhone.png"
                },
                {
                    title: "info@9qianbi.com",
                    info: "email",
                    pic: "aboutUsContactEmail.png"

                },
                {
                    title: "Tianfu Software Park, Chengdu High-tech Zone",
                    info: "address",
                    pic: "aboutUsContactAddress.png"
                },
            ]
        }

    }
}
export default info