/*
 * @Author: 苏宇轩
 * @Date: 2022-11-02 14:50:17
 * @LastEditors:
 * @LastEditTime: 2022-11-02 14:50:58
 * @Description:
 */
import enLocale from 'element-ui/lib/locale/lang/en'; // 引入element语言包
import AllWord from './data/index';

// 中文对应的英文
const en = {
  nav: {
    info: AllWord.Nav.en,
  },
  indexPage: {
    info: AllWord.IndexPage.en,
  },
  au: {
    info: AllWord.AboutUs.en,
  },
  cc: {
    info: AllWord.CooperationCases.en,
  },
  hp: {
    info: AllWord.HardwareProducts.en,
  },
  ip: {
    info: AllWord.InvestmentPromotion.en,
  },
  ss: {
    info: AllWord.SoftwareSystem.en,
  },
  cp: {
    info: AllWord.Cooperate.en,
  },
  foot: {
    info: AllWord.Foot.en,
  },
  ...enLocale,
};

export default en;
