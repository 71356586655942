const info = {
    cn: {
        severTitle: "",
        sever: {
            title: "为电动车安全停车充电提供全套软件硬件基础服务",
            list: [
                {
                    title: "电动自行车智能充电",
                    info: "充满自停、断电续充、过载超时断电",
                    titleBack: "电动自行车智能充电",
                    infoBack: "为住宅小区、行政办公区、工业园区、各类学校等场景提供安全便捷的停车，具有多种开门方式，支持包月停车、临时停车等功能"
                },
                {
                    title: "电动自行车智能停车",
                    info: "多种开门方式、智能停车",
                    titleBack: "电动自行车智能停车",
                    infoBack: "为住宅小区、行政办公区、工业园区、各类学校等场景提供安全便捷的充电，具有充满自停、断电续充、过载超时断电等功能"
                },

                {
                    title: "新能源电动汽车交流直流充电",
                    info: "3.5KW/7KW/11KW/30KW/120KW/160KW交流直流桩",
                    titleBack: "新能源电动汽车交流直流充电",
                    infoBack: "为私家车主、运营商、合作伙伴提供3.5KW、7KW、11KW、30KW、120KW、140KW交流直流充电桩"
                },
            ]
        },
        schemeTitle: "整体解决方案",
        vehiclesInfo: {
            titleChName: "为电动车安全停车充电提供全套软件硬件服务",
            titleEnName: "Provide a complete set of software and hardware services for safe parking and charging of electric vehicles",
        },
        installationCasesInfo: {
            titleChName: "安装案例",
            titleEnName: "Installation Cases",
        },
        overallSolutionInfo: {
            titleChName: "整体解决方案",
            titleEnName: "Overall solution",
        },
        scenarioPlanTitle: {
            titleChName: "场景方案",
            titleEnName: "Scenario Scheme",
        },
        scenarioPlanList: [
            {
                img: '/index/scenarioPlan-1.jpg',
                title_1: '双路智能充电桩充电场景',
                title_2: '智慧消防+共享充电',
                leftTitle: [{
                    title: '共享充电',
                    content: '安装智能充电桩设备，在线功率监测，控制电流输入，实时获取设备状态，远程进行设备控制'
                },
                {
                    title: '智慧消防',
                    content: '联网烟雾报警装置，配置自动喷淋干粉灭火器，防止无人员主动灭火导致火灾扩大，可远程灭火'
                }
                ]
            }, {
                img: '/index/scenarioPlan-2.png',
                title_1: '8+2路智能充电桩充电场景',
                title_2: '智慧消防+共享充电',
                leftTitle: [{
                    title: '共享充电',
                    content: '安装智能充电桩设备，在线功率监测，控制电流输入，实时获取设备状态，远程进行设备控制'
                },
                {
                    title: '智慧消防',
                    content: '联网烟雾报警装置，配置自动喷淋干粉灭火器，防止无人员主动灭火导致火灾扩大，可远程灭火'
                }
                ]
            },
            {
                img: '/index/scenarioPlan-3.jpg',
                title_1: '电动自行车智能停车+充电场景',
                title_2: '无人看守+智慧消防+共享充电+共享停车',
                leftTitle: [{
                    title: '共享充电',
                    content: '安装智能充电桩设备，在线功率监测，控制电流输入，实时获取设备状态，远程进行设备控制'
                },
                {
                    title: '共享停车',
                    content: '安装网络门禁主机设备，实时获取设备状态，远程进行设备控制，支持包月、临停多种停车方式'
                },
                {
                    title: '智慧消防',
                    content: '联网烟雾报警装置，配置自动喷淋干粉灭火器，防止无人员主动灭火导致火灾扩大，可远程灭火'
                }
                ]
            }],
        operationPlatformInfo: {
            titleChName: "运营平台管理系统简单方便",
            titleEnName: "The operation platform management system is simple and convenient",
            titleWord: "数字化运营平台管理系统，全面对设备、用户、支付进行管理"
        },
        operationPlatformlist: [
            {
                tabActiveImage: "indexOperationOneActive.png",
                tabNoActiveImage: "indexOperationOneNoActive.png",
                tabName: "多种计费模式",
                infoTitle: "多种计费方式灵活配置",
                infoWord: "按功率分段计费、时间计费、电量计费、包月充电等通过系统远程设置。",
                infoImage: "indexOperationOneBig.png"
            },
            {
                tabActiveImage: "indexOperationTwoActive.png",
                tabNoActiveImage: "indexOperationTwoNoActive.png",
                tabName: "多种开电方式",
                infoTitle: "多种开电方式",
                infoWord: "支持包月刷卡、微信扫码、充值卡开电",
                infoImage: "indexOperationTwoBig.png"
            }, {
                tabActiveImage: "indexOperationThreeActive.png",
                tabNoActiveImage: "indexOperationThreeNoActive.png",
                tabName: "设备监控管理",
                infoTitle: "设备监控管理",
                infoWord: "可实时监测设备状态，设备进行远程重启、禁用、启用等操作。支持远程升级",
                infoImage: "indexOperationThreeBig.png"
            }, {
                tabActiveImage: "indexOperationFourActive.png",
                tabNoActiveImage: "indexOperationFourNoActive.png",
                tabName: "系统权限设置",
                infoTitle: "系统权限设置",
                infoWord: "可进行部门、岗位、角色、人员管理。对人员进行数据权限与菜单功能权限的设置",
                infoImage: "indexOperationFourBig.png"
            }, {
                tabActiveImage: "indexOperationFiveActive.png",
                tabNoActiveImage: "indexOperationFiveNoActive.png",
                tabName: "使用用户管理",
                infoTitle: "使用用户管理",
                infoWord: "可通过PC端后台或小程序管理端对包月停车用户、包月充电用户、充电充值卡用户进行管理。进行用户的增删改查、启用、 禁用、更换卡片、到期日期等功能。",
                infoImage: "indexOperationFiveBig.png"
            }, {
                tabActiveImage: "indexOperationSixActive.png",
                tabNoActiveImage: "indexOperationSixNoActive.png",
                tabName: "各类数据分析",
                infoTitle: "各类数据分析",
                infoWord: "可实时通过系统查看门禁出入记录、缴费记录、充电记录、充值记录等各项数据",
                infoImage: "indexOperationSixBig.png"
            },

        ],
        becomePartnerInfo: {
            titleChName: "成为合伙人",
            titleEnName: "Become a partner",
        },
        becomePartnerList: [
            {
                image: "becomePartnerOne.png",
                title: "我有场地",
                word: "有车棚、车库等场地"
            },
            {
                image: "becomePartnerTwo.png",
                title: "我有资金",
                word: "投资的资金"
            },
            {
                image: "becomePartnerThree.png",
                title: "我有资源",
                word: "有物业、开发商、园区等资源"
            },
            {
                image: "becomePartnerFour.png",
                title: "我想挣钱",
                word: "愿意投入人力、共同开拓市场"
            },
        ],

        cooperativePartnerInfo: {
            titleChName: "合作伙伴",
            titleEnName: "Cooperative partner",
        },
        provideTitle: {
            titleChName: "我们还可以提供",
            titleEnName: "We can also provide",
        },
        provideList: [
            {
                title: '微信公众号/小程序对接',
                content: '微信公众号/小程序对接，运营商自主管理（营收资金、终端用户、增值服务）',
                icon: '/index/tigong-1.png'
            }, {
                title: '私有化部署',
                content: '为用户提供私有化部署，可提供代运营、代管理服务',
                icon: '/index/tigong-2.png'
            }, {
                title: '硬件定制开发',
                content: '电路板设计、贴片、模具定制、贴牌',
                icon: '/index/tigong-3.png'
            }, {
                title: '数据接口服务',
                content: '提供硬件、软件API接口，底层通讯协议，协助客户开发',
                icon: '/index/tigong-4.png'
            },
            {
                title: '4G支付模块',
                content: '提供4G支付模块出售',
                icon: '/index/tigong-5.png'
            },
        ],
        overallSolutionlist: [
            {
                title: "安全智能充电",
                leftList: ['多种保护', '过充保护', '过载保护'],
                rightList: ['设备管理', '充电记录', '设备监测'],
                img: '/index/indexOverallOne.png'
            },
            {
                title: "安全智能停车",
                leftList: ['多种开门方式', 'IC刷卡', '手机开门'],
                rightList: ['停车状态监测', '可接人脸', '临停记录'],
                img: '/index/indexOverallTwo.png'
            },
            {
                title: "用户使用",
                leftList: ['刷卡充电停车', '自主缴费', '订单查询'],
                rightList: ['扫码充电停车', '自主办卡', '缴费记录'],
                img: '/index/indexOverallThree.png'
            },
            {
                title: "运营管理",
                leftList: ['实时监控', '远程升级', '权限分配'],
                rightList: ['设备控制', '记录查询', '站点配置'],
                img: '/index/indexOverallFour.png'
            },
        ],
        deviceName: {
            gateway: "LTE-LORA工业级网关", // LTE-LORA industrial gateway
            host: "网络门禁主机", //Network access control host
            rfid: "RFID读头",    //RFID reader
            charge: "双路智能充电桩",  //充Dual intelligent charging pile
            outIc: "出门IC刷卡器",  //Go out IC card reader
            smoke: "智能烟感",  //Intelligent smoke sensing
            eCharge: "8+2路智能充电桩",  //8+2 intelligent charging pile
            extinguisher: "悬挂式干粉灭火器", //Hanging dry powder fire extinguisher
            surveillance: "视频监控",   //Video surveillance
            iAoIc: "进出IC刷卡器",   //In and out of IC card reader
            cameras: "4G/5G摄像机"
        },
    },
    en: {
        sever: {
            title: "To provide a full set of software and hardware basic services for electric vehicle safe parking and charging",
            list: [
                {
                    title: "Smart charging for electric bicycles",
                    info: "Full stop, power off, overload and timeout power off",
                    titleBack: "Smart charging for electric bicycles",
                    infoBack: "For residential areas, administrative areas, industrial parks, all kinds of schools and other scenes to provide safe and convenient parking, with a variety of door opening, support monthly parking, temporary parking and other functions"
                },
                {
                    title: "Smart parking for electric bicycles",
                    info: "Multiple ways to open doors, intelligent parking",
                    titleBack: "Smart parking for electric bicycles",
                    infoBack: "It provides safe and convenient charging for residential areas, administrative offices, industrial parks, all kinds of schools and other scenes, with full automatic stop, power off, overload and timeout power off and other functions"
                },

                {
                    title: "New energy electric vehicle AC DC charging",
                    info: "3.5KW/7KW,11KW/30KW/120KW/160KW ac dc pile",
                    titleBack: "New energy electric vehicle AC DC charging",
                    infoBack: "Provide 3.5KW, 7KW, 11KW, 30KW, 120KW and 140KW AC and DC charging piles for private car owners, operators and partners"
                },
            ]
        },
        schemeTitle: "Overall solution",
        vehiclesInfo: {
            titleChName: "Provide a complete set of software and hardware services for safe parking and charging of electric vehicles",
            titleEnName: "为电动车安全停车充电提供全套软件硬件服务 ",
        },
        installationCasesInfo: {
            titleChName: "Installation Cases",
            titleEnName: "安装案例",
        },
        overallSolutionInfo: {
            titleChName: "Overall solution",
            titleEnName: "整体解决方案",
        },
        scenarioPlanTitle: {
            titleChName: "Scenario Scheme",
            titleEnName: "场景方案",
        },
        scenarioPlanList: [
            {
                img: '/index/scenarioPlan-1.jpg',
                title_1: 'Dual intelligent charging pile charging scenario',
                title_2: 'Intelligent fire fighting + shared charging',
                leftTitle: [{
                    title: 'Shared charging',
                    content: 'Install intelligent charging pile equipment, online power monitoring, control current input, real-time acquisition of equipment status, remote control of equipment'
                },
                {
                    title: 'Intelligent fire protection',
                    content: 'Network smoke alarm device, equipped with automatic spray dry powder fire extinguisher, prevent no personnel active fire expansion, remote fire extinguishing'
                }
                ]
            }, {
                img: '/index/scenarioPlan-2.png',
                title_1: '8+2 intelligent charging pile charging scenario',
                title_2: 'Intelligent fire fighting + shared charging',
                leftTitle: [{
                    title: 'Shared charging',
                    content: 'Install intelligent charging pile equipment, online power monitoring, control current input, real-time acquisition of equipment status, remote control of equipment'
                },
                {
                    title: 'Intelligent fire protection',
                    content: 'Network smoke alarm device, equipped with automatic spray dry powder fire extinguisher, prevent no personnel active fire expansion, remote fire extinguishing'
                }
                ]
            },
            {
                img: '/index/scenarioPlan-3.jpg',
                title_1: 'Electric bicycle intelligent parking + charging scene',
                title_2: 'Unguarded + intelligent fire fighting + shared charging + shared parking',
                leftTitle: [{
                    title: 'Shared charging',
                    content: 'Installation of intelligent charging pile equipment, online power monitoring, control current input, real-time acquisition of equipment status, remote control of equipment'
                },
                {
                    title: 'Shared parking',
                    content: 'Install the network access control host device, obtain the status of the device in real time, control the device remotely, and support various parking modes such as monthly payment and temporary parking'
                },
                {
                    title: 'Intelligent fire protection',
                    content: 'Networked smoke alarm device, equipped with automatic spray dry powder extinguisher, to prevent fire expansion without active extinguishing, remote extinguishing'
                }
                ]
            }],
        operationPlatformInfo: {
            titleChName: "The operation platform management system is simple and convenient",
            titleEnName: "运营平台管理系统简单方便 ",
            titleWord: "Digital operation platform management system, comprehensive equipment, users, payment management"
        },
        operationPlatformlist: [
            {
                tabActiveImage: "indexOperationOneActive.png",
                tabNoActiveImage: "indexOperationOneNoActive.png",
                tabName: "Multiple billing modes",
                infoTitle: "Multiple billing modes",
                infoWord: "According to the power level charging, time charging, electricity charging, monthly charge through the system remote Settings.",
                infoImage: "indexOperationOneBig.png"
            },
            {
                tabActiveImage: "indexOperationTwoActive.png",
                tabNoActiveImage: "indexOperationTwoNoActive.png",
                tabName: "Multiple ways to turn on power",
                infoTitle: "Multiple ways to turn on power",
                infoWord: "Support monthly credit card, wechat code scanning, recharge card open",
                infoImage: "indexOperationTwoBig.png"
            }, {
                tabActiveImage: "indexOperationThreeActive.png",
                tabNoActiveImage: "indexOperationThreeNoActive.png",
                tabName: "Equipment monitoring management",
                infoTitle: "Equipment monitoring management",
                infoWord: "You can monitor device status in real time, and remotely restart, disable, and enable the device. Support remote upgrade",
                infoImage: "indexOperationThreeBig.png"
            }, {
                tabActiveImage: "indexOperationFourActive.png",
                tabNoActiveImage: "indexOperationFourNoActive.png",
                tabName: "System permission setting",
                infoTitle: "System permission setting",
                infoWord: "Able to manage departments, posts, roles and personnel. Set data permissions and menu permissions for personnel",
                infoImage: "indexOperationFourBig.png"
            }, {
                tabActiveImage: "indexOperationFiveActive.png",
                tabNoActiveImage: "indexOperationFiveNoActive.png",
                tabName: "Use user management",
                infoTitle: "Use user management",
                infoWord: "It can manage monthly parking users, monthly charging users and charging card users through the background of PC side or small program management side. Add, delete, modify, enable, disable, replace the card, expiration date and other functions of the user.",
                infoImage: "indexOperationFiveBig.png"
            }, {
                tabActiveImage: "indexOperationSixActive.png",
                tabNoActiveImage: "indexOperationSixNoActive.png",
                tabName: "Recharge records and other data",
                infoTitle: "Recharge records and other data",
                infoWord: "Real time access control records, payment records, charging records, recharge records, and other data can be viewed through the system",
                infoImage: "indexOperationSixBig.png"
            },

        ],
        becomePartnerInfo: {
            titleChName: "Become a partner",
            titleEnName: "成为合伙人",
        },
        becomePartnerList: [
            {
                image: "becomePartnerOne.png",
                title: "I have a venue",
                word: "There are carport, garage and other places"
            },
            {
                image: "becomePartnerTwo.png",
                title: "I have the funds",
                word: "Invested funds"
            },
            {
                image: "becomePartnerThree.png",
                title: "I have resources",
                word: "There are property, developers, parks and other resources"
            },
            {
                image: "becomePartnerFour.png",
                title: "I want to make money",
                word: "Willing to invest manpower and jointly explore the market"
            },
        ],

        cooperativePartnerInfo: {
            titleChName: "Cooperative partner",
            titleEnName: "合作伙伴",
        },
        provideTitle: {
            titleChName: "We can also provide",
            titleEnName: "我们还可以提供",
        },
        provideList: [
            {
                title: 'Wechat public account/mini program docking',
                content: 'Wechat official account/mini program docking, operator independent management (revenue funds, end users, value-added services)',
                icon: '/index/tigong-1.png'
            }, {
                title: 'Privatization deployment',
                content: 'For users to provide private deployment, can provide agent operation, agent management services',
                icon: '/index/tigong-2.png'
            }, {
                title: 'Hardware custom development',
                content: 'Circuit board design, patch, mold customization, OEM',
                icon: '/index/tigong-3.png'
            }, {
                title: 'Data interface service',
                content: 'Provide hardware and software API interface, the underlying communication protocol, to assist customer development',
                icon: '/index/tigong-4.png'
            },
            {
                title: '4G payment module',
                content: 'Offer 4G payment module for sale',
                icon: '/index/tigong-5.png'
            }
        ],
        overallSolutionlist: [
            {
                title: "Safe and intelligent charging",
                leftList: ['Multiple protection', 'Overcharge protection', 'Overload protection'],
                rightList: ['Equipment management', 'Charge record', 'Equipment monitoring'],
                img: '/index/indexOverallOne.png'
            },
            {
                title: "Safe intelligent parking",
                leftList: ['Multiple opening modes', 'IC swipe card', 'Mobile phone door opening'],
                rightList: ['Parking condition monitoring', 'Connectable face', 'Stop-start record'],
                img: '/index/indexOverallTwo.png'
            },
            {
                title: "User use",
                leftList: ['Charge/park by swiping your card', 'Independent payment', 'Order inquiry'],
                rightList: ['Scan code for charging/parking', 'Self-sponsored card', 'Payment record'],
                img: '/index/indexOverallThree.png'
            },
            {
                title: "Operation management",
                leftList: ['Real-time monitoring', 'Remote upgrade', 'Authority allocation'],
                rightList: ['Equipment control', 'Record query', 'Site configuration'],
                img: '/index/indexOverallFour.png'
            },
        ],
        deviceName: {
            gateway: "LTE-LORA industrial gateway", // 
            host: "Network access control host", //
            rfid: "RFID reader",    //
            charge: "Dual intelligent charging pile",  //充
            outIc: "Go out IC card reader",  //
            smoke: "Intelligent smoke sensing",  //
            eCharge: "8+2 intelligent charging pile",  //
            extinguisher: "Hanging dry powder fire extinguisher", //
            surveillance: "Video surveillance",   //
            iAoIc: "In and out of IC card reader",   //
            cameras: "4G/5G cameras"
        },


    }
}
export default info