/*
 * @Author: 苏宇轩
 * @Date: 2022-11-02 14:50:23
 * @LastEditors: 
 * @LastEditTime: 2022-11-02 14:51:08
 * @Description: 
 */
//导入两个文件
import en from './en';
import cn from './cn';
export default {
  en: en,
  cn: cn
}