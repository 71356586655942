import Vue from 'vue';
import Vuex from 'vuex';
import router from './modules/router'
import lange from './modules/lange'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    router,
    lange
  },
});
