<template>
    <div class="footBottom-main" :style="{
        paddingBottom: showForm ? paddingBottom + 'px' : '0px'
    }">
        <div class="footBottom-top">
            <div class="container viewRow viewbetweenStart viewWrap">
                <div class="row col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 p-0" style="margin: 0 auto;">
                    <div class="col-xl-8 col-lg-6 col-md-8 col-sm-12 col-12 p-0 mb-2">
                        <p class="footBottom-title">{{ $t('foot.info.bigTitle1') }}</p>
                        <div class="footBottom-nav viewRow viewStart viewWrap">
                            <div :class="['footBottom-nav-box', { navActive: item.isCurrent }]"
                                v-for="(item, index) in routerList" :key="index" @click="goPage(item)">
                                <p class="footBottom-nav-title" :style="{
                                    height: language == 'cn' ? '17px' : '48px'
                                }">{{ $t(`${item.meta.languageValue}`) }}</p>
                                <!-- <div class="footBottom-nav-line"></div> -->
                            </div>
                        </div>
                        <div class="showBig">
                            <div class="text-left copyrightText mt-2">
                                {{ $t('foot.info.copyright') }}
                            </div>
                            <div class="text-left copyrightText" @click="goICP">
                                {{ $t('foot.info.filings') }}
                            </div>
                        </div>


                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-4 col-sm-12 col-12 p-0 mb-2">
                        <p class="footBottom-title">{{ $t('foot.info.bigTitle2') }}</p>
                        <div class="footBottom-info">
                            <div class="footBottom-info-box viewRow viewStartStart">
                                <p class="text-1">{{ $t('foot.info.contactPhone') }}</p>
                                <p class="text-2 ml-2">400-668-3398</p>
                            </div>
                            <div class="footBottom-info-box viewRow viewStartStart">
                                <p class="text-1">{{ $t('foot.info.contactEmail') }}</p>
                                <p class="text-2 ml-2">info@9qianbi.com</p>
                            </div>
                            <div class="footBottom-info-box viewRow viewStartStart">
                                <p class="text-1">{{ $t('foot.info.contactAddress') }}</p>
                                <p class="text-2 ml-2">{{ $t('foot.info.address') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="guanzhu-box viewRow col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                    <div class="viewCol viewStartStart">
                        <p class="footBottom-title">{{ $t('foot.info.bigTitle3') }}</p>
                        <div class="footBottom-img-list viewRow viewStartStart viewWrap">
                            <!-- <div class="footBottom-img-box viewCol viewStart">
                            <img class="img" :src="`${$ImgUrl}/public/footBottom-img-1.png`" alt="">
                            <p>铅笔智能公众号</p>
                        </div> -->
                            <div class="footBottom-img-box viewCol viewStart">
                                <img class="img" :src="`${$ImgUrl}/public/footBottom-img-10.png`" alt="">
                                <p>{{ $t('foot.info.followUsText1') }}</p>
                            </div>
                            <div class="footBottom-img-box viewCol viewStart">
                                <img class="img" :src="`${$ImgUrl}/public/footBottom-img-3.png`" alt="">
                                <p>{{ $t('foot.info.followUsText2') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="showSmall">
                    <div class="text-left copyrightText">
                        {{ $t('foot.info.copyright') }}
                    </div>
                    <div class="text-left copyrightText " @click="goICP">
                        {{ $t('foot.info.filings') }}
                    </div>
                </div>
            </div>

            <div class="footBottom-line"></div>
        </div>

        <div class="footBottom-bottom"
            :style="showForm ? `opacity: 1;background-image: url('${$ImgUrl}/public/footBottom-bg.png');` : `opacity: 0;bottom:${-paddingBottom}px;background-image: url('${$ImgUrl}/public/footBottom-bg.png');`">
            <div class=" footBottom-bottom-main container viewRow viewbetween viewWrap">
                <div class="footBottom-bottom-box col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12 p-0 mb-1">
                    <p class="footBottom-bottom-title">{{ formText.name }}</p>
                    <input class="footBottom-bottom-input" v-model="subForm.userName" type="text"
                        :placeholder="formText.namePlaceholder" />
                </div>
                <div class="footBottom-bottom-box col-xl-2 col-lg-3 col-md-5 col-sm-12 col-12 p-0 mb-1">
                    <p class="footBottom-bottom-title">{{ formText.phone }}</p>
                    <input class="footBottom-bottom-input" v-model="subForm.phone" type="text"
                        :placeholder="formText.phonePlaceholder" @input="onNumberInputChange" />
                </div>
                <div class="footBottom-bottom-box col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12 p-0 mb-1">
                    <p class="footBottom-bottom-title">{{ formText.select }}</p>
                    <div class="footBottom-bottom-btn-box viewRow viewbetween viewWrap">
                        <button @click="selectBtn(1)"
                            class="footBottom-bottom-btn col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6"
                            :class="{ 'avtive-btn': subForm.enterJoinType.includes(1) }">{{ formText.selectValue_1
                            }}</button>
                        <button @click="selectBtn(2)"
                            class="footBottom-bottom-btn col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6"
                            :class="{ 'avtive-btn': subForm.enterJoinType.includes(2) }">{{ formText.selectValue_2
                            }}</button>
                    </div>
                </div>
                <div class="footBottom-right mb-1 viewCol viewEndEnd col-xl-1 col-lg-12 col-md-6 col-sm-4 col-12 p-0">
                    <button class="sub-btn" @click.stop="sub">{{ formText.sub }}</button>
                </div>
                <img @click="showForm = false" class="close-img" :src="`${$ImgUrl}/public/footBottom-close.png`" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { subForm } from '@/api/index'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            paddingBottom: 0,
            showForm: true,
            formText: {
                name: this.$t('cp.info.formText.name'),
                namePlaceholder: this.$t('cp.info.formText.namePlaceholder'),
                phone: this.$t('cp.info.formText.phone'),
                phonePlaceholder: this.$t('cp.info.formText.phonePlaceholder'),
                select: this.$t('cp.info.formText.select'),
                selectValue_1: this.$t('cp.info.formText.selectValue_1'),
                selectValue_2: this.$t('cp.info.formText.selectValue_2'),
                sub: this.$t('cp.info.formText.sub'),
            },
            subForm: {
                sourceType: 1,
                enterJoinType: [],
                phone: "",
                userName: ""
            },
        }
    },
    mounted() {
        let boxDom = document.getElementsByClassName('footBottom-bottom')[0]
        this.paddingBottom = boxDom.offsetHeight
        window.addEventListener("resize", () => {
            boxDom = document.getElementsByClassName('footBottom-bottom')[0]
            this.paddingBottom = boxDom.offsetHeight
        });
    },
    computed: {
        ...mapGetters(['language']),
        routerList() {
            let routerList = JSON.parse(JSON.stringify(this.$store.getters.routerList))
            if (this.$route.meta.navGroup) {
                let navGroupList = this.$route.meta.navGroup.split('-')
                routerList = this.setRouter(routerList, navGroupList)
            }
            return routerList
        },
    },
    methods: {
        goICP() {
            window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
        },
        selectBtn(value) {
            if (this.subForm.enterJoinType.includes(value)) {
                this.subForm.enterJoinType.splice(this.subForm.enterJoinType.indexOf(value), 1)
            } else {
                this.subForm.enterJoinType.push(value)
            }
        },
        onNumberInputChange(e) {
            // 将非数字字符替换为空字符串
            this.subForm.phone = e.target.value.replace(/[^\d]/g, '');
        },
        sub() {
            let form = {}
            if (!this.subForm.userName) {
                return this.$message.warning(this.formText.namePlaceholder)
            }
            form.userName = this.subForm.userName
            if (!this.subForm.phone) {
                return this.$message.warning(this.formText.phonePlaceholder)
            }
            form.phone = this.subForm.phone
            if (this.subForm.enterJoinType.length == 0) {
                form.enterJoinType = ''
            } else if (this.subForm.enterJoinType.length == 2) {
                form.enterJoinType = 2
            } else if (this.subForm.enterJoinType.includes(1)) {
                form.enterJoinType = 0
            } else {
                form.enterJoinType = 1
            }
            form.sourceType = 1
            subForm(form).then(res => {
                this.$message.success('提交成功')
                this.subForm = {
                    sourceType: 1,

                    enterJoinType: [],
                    phone: "",
                    userName: ""
                }
                this.showForm = false;
            })
        },
        // 每个级别路由常亮
        setRouter(list, navGroupList) {
            list.map(item => {
                let itemNavGroup = item.meta.navGroup.split('-')
                //哪级菜单
                let levegrade = this.getLevegrade(item.meta.navGroup)
                if (levegrade == 1) {
                    if (itemNavGroup[0] == navGroupList[0]) {
                        item.isCurrent = true
                    } else {
                        item.isCurrent = false
                    }
                } else if (levegrade == 2) {
                    if (itemNavGroup[0] == navGroupList[0] && itemNavGroup[1] == navGroupList[1]) {
                        item.isCurrent = true
                    } else {
                        item.isCurrent = false
                    }
                } else {
                    if (itemNavGroup[0] == navGroupList[0] && itemNavGroup[1] == navGroupList[1] && itemNavGroup[2] == navGroupList[2]) {
                        item.isCurrent = true
                    } else {
                        item.isCurrent = false
                    }
                }
                if (item.children && item.children.length != 0) {
                    item.children = this.setRouter(item.children, navGroupList)
                }
            })
            return list
        },
        getLevegrade(navGroup) {
            let itemNavGroup = navGroup.split('-')
            let levegrade
            if (itemNavGroup[1] == 0) {
                levegrade = 1
            } else if (itemNavGroup[2] == 0) {
                levegrade = 2
            } else {
                levegrade = 3
            }
            return levegrade
        },
        goPage(item) {
            if (item.path && this.$route.meta.navGroup != item.meta.navGroup) {
                this.$router.push(item.path)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.showBig {
    display: none;
}

.showSmall {
    display: block;
}

// col-xl-
@media (min-width: 1200px) {
    .guanzhu-box {
        justify-content: flex-end;
        align-items: flex-start;
    }

    .showBig {
        display: block !important;
    }

    .showSmall {
        display: none !important;
    }
}

// col-lg-
@media (min-width: 992px) and (max-width: 1200px) {
    .guanzhu-box {
        justify-content: flex-start;
        align-items: flex-start;
    }

}

// col-md-
@media (min-width: 768px) and (max-width: 992px) {
    .guanzhu-box {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

// col-sm-
@media (min-width: 576px) and (max-width: 768px) {
    .guanzhu-box {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

// col-
@media(max-width: 576px) {
    .guanzhu-box {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media (max-width: 419px) {
    .footBottom-nav-box:nth-of-type(3n) {
        margin-right: 0 !important;
    }

    .footBottom-nav-box {
        margin-right: 0 !important;
        width: 100% !important;
    }

    .footBottom-bottom-input {
        height: 40px !important;
        line-height: 40px !important;
        margin-top: 4px !important;
    }

    .footBottom-bottom-btn-box {
        margin-top: 4px !important;

    }

    .sub-btn {
        width: 100% !important;
        height: 40px !important;
        line-height: 40px !important;
    }


}

@media (max-width: 576px) {
    .close-img {
        right: 15px !important;
    }

    .footBottom-bottom-input {
        height: 40px !important;
        line-height: 40px !important;
        margin-top: 4px !important;
    }

    .footBottom-bottom-btn-box {
        margin-top: 4px !important;

    }

    .sub-btn {
        width: 100% !important;
        height: 40px !important;
        line-height: 40px !important;
    }
}

.footBottom-top {
    width: 100%;
    background: #2e3033;
    padding: 30px 0 0;

    .footBottom-line {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #8F959D;
        margin-top: 40px;
    }

    .copyrightText {
        font-size: 13px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #8f959d;
        line-height: 24px;
    }

    .footBottom-title {
        font-size: 1.25rem;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 0;
    }

    .footBottom-nav {
        margin-top: -2px;
        // margin-right: 50px;

        .footBottom-nav-box {
            cursor: pointer;
            margin-right: 50px;
            margin-top: 30px;

            .footBottom-nav-title {
                width: 96px;
                word-break: break-all;
                word-wrap: break-word;
                font-size: 16px;
                font-weight: 400;
                color: #8f959d;
                margin-bottom: 1rem;
            }



            .footBottom-nav-line {
                width: 96px;
                border-bottom: 1px solid #8f959d;
            }

        }

        .footBottom-nav-box:hover {
            p {
                color: #fff;
            }

            .footBottom-nav-line {
                border-bottom: 1px solid #fff;
            }
        }

        .footBottom-nav-box:nth-of-type(3n) {
            margin-right: 0;
        }

        .navActive {
            p {
                color: #fff !important;
            }

            .footBottom-nav-title {
                color: #fff;

            }

            .footBottom-nav-line {
                border-bottom: 1px solid #fff !important;
            }
        }
    }

    .footBottom-info {
        margin-top: -14px;

        .footBottom-info-box {
            margin-top: 30px;

            p {
                margin-bottom: 0;
            }

            .text-1 {
                font-size: 1rem;
                font-weight: 400;
                color: #8f959d;
            }

            .text-2 {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }

    .footBottom-img-list {
        margin-top: 20px;

        .footBottom-img-box {
            margin-right: 16px;

            .img {
                width: 124px;
                height: 124px;
            }

            p {
                text-align: center;
                max-width: 147px;
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                margin-top: 7px;
            }
        }
    }

}

.footBottom-bottom {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 120px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999999;
    opacity: 1;
    transition: 2s;

    .footBottom-bottom-main {
        padding-top: 20px;
        padding-bottom: 20px;
        box-sizing: border-box;
        position: relative;

        .footBottom-bottom-box {
            .footBottom-bottom-title {
                white-space: nowrap;
                line-height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                margin-bottom: 0;
            }

            .footBottom-bottom-input {
                width: 100%;
                height: 56px;
                border: 2px solid #ffffff;
                border-radius: 6px;
                line-height: 56px;
                background: transparent;
                font-size: 14px;
                font-weight: 400;
                color: #ffffff;
                padding-left: 11px;
                margin-top: 14px;
            }

            .footBottom-bottom-input::-webkit-input-placeholder,
            textarea::-webkit-input-placeholder {
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }

            .footBottom-bottom-input.footBottom-bottom-input:-moz-placeholder,
            textarea:-moz-placeholder {
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }

            .footBottom-bottom-input::-moz-placeholder,
            textarea::-moz-placeholder {
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }

            .footBottom-bottom-input:-ms-input-placeholder,
            textarea:-ms-input-placeholder {
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.5);
            }

            .footBottom-bottom-btn-box {
                margin-top: 14px;
                height: 56px;

                .footBottom-bottom-btn {
                    // width: 154px;
                    // display: inline-block;
                    // width: auto;
                    padding: 0 17px;
                    box-sizing: border-box;
                    border: 2px solid #ffffff;
                    border-radius: 6px;
                    height: 100%;
                    background: transparent;
                    font-size: 16px;
                    font-weight: 400;
                    color: #ffffff;
                }

                .avtive-btn {
                    background: #fff;
                    color: #e58419;
                }

                // .footBottom-bottom-btn:nth-of-type(2) {
                //     margin-left: 20px;
                // }
            }

        }

        .close-img {
            position: absolute;
            top: 10px;
            right: 0;
            width: 18px;
            height: 18px;
        }

        .footBottom-right {
            height: 84px;

            .sub-btn {
                width: 145px;
                height: 56px;
                background: #ffffff;
                border-radius: 6px;
                line-height: 56px;
                font-size: 16px;
                font-weight: 400;
                color: #e58419;
                border: none;
            }
        }
    }

}
</style>