/*
 * @Author: 苏宇轩
 * @Date: 2022-11-02 14:50:11
 * @LastEditors:
 * @LastEditTime: 2022-11-02 14:50:53
 * @Description:
 */
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'; // 引入element语言包
import AllWord from './data/index';
// 需要转换的中文
const cn = {
  nav: {
    info: AllWord.Nav.cn,
  },
  indexPage: {
    info: AllWord.IndexPage.cn,
  },
  au: {
    info: AllWord.AboutUs.cn,
  },
  cc: {
    info: AllWord.CooperationCases.cn,
  },
  hp: {
    info: AllWord.HardwareProducts.cn,
  },
  ip: {
    info: AllWord.InvestmentPromotion.cn,
  },
  ss: {
    info: AllWord.SoftwareSystem.cn,
  },
  cp: {
    info: AllWord.Cooperate.cn,
  },
  foot: {
    info: AllWord.Foot.cn,
  },
  ...zhLocale,
};

export default cn;
