import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import '@/styles/reset.css' //样式初始化
// import '@/utils/flexible' //rem适配
import '@/styles/flex.css' //flex布局
// 引入jQuery、bootstrap
import i18n from './i18n/i18n';
import $ from 'jquery'
import 'bootstrap'
// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css';
// 全局注册 $
Vue.prototype.$ = $

import '../theme/index.css'

import { scrolDisplay, } from '@/utils/directive'
//渐入效果
Vue.directive("scrolDisplay", scrolDisplay)

import '@/styles/swiper-3.4.2.min.css'
import '@/utils/swiper-3.4.2.min.js'


import { imgUrl } from './config/env';
Vue.prototype.$ImgUrl = imgUrl
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
