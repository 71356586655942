import router from "@/router"
const getLevegrade = (navGroup) => {
    let itemNavGroup = navGroup.split('-')
    let levegrade
    if (itemNavGroup[1] == 0) {
        levegrade = 1
    } else if (itemNavGroup[2] == 0) {
        levegrade = 2
    } else {
        levegrade = 3
    }
    return levegrade
}
const isHaveChildren = (navGroup, list) => {
    var isHave = false;
    //下一级
    let children = [];
    let levegrade = getLevegrade(navGroup)
    navGroup = navGroup.split('-')
    for (let i in list) {
        let item = list[i]
        let itemLevegrade = getLevegrade(item.meta.navGroup)
        let itemNavGroup = item.meta.navGroup.split('-')
        if (levegrade == 1 && itemLevegrade == 2) {
            //找二级
            if (navGroup[0] == itemNavGroup[0] && itemNavGroup[2] == 0) {
                children.push(item);
                isHave = true
            }
        } else if (levegrade == 2 && itemLevegrade == 3) {
            //找三级
            if (navGroup[0] == itemNavGroup[0] && navGroup[1] == itemNavGroup[1]) {
                children.push(item);
                isHave = true
            }
        } else {

        }

    }
    return {
        isHave,
        children,
    };
}

const circulation = (data, bigdata) => {
    //循环要插入的地方
    for (let index in data) {
        //返回值 isHaveChildren.isHave 是否有下一级
        //返回值 isHaveChildren.children 下一级
        var isHaveChildrenReturn = isHaveChildren(
            data[index].meta.navGroup,
            bigdata
        );
        //如果有下一级
        if (isHaveChildrenReturn.isHave) {
            //下一级内容为 isHaveChildren.children
            data[index].children = isHaveChildrenReturn.children;
            //再次走这里
            circulation(data[index].children, bigdata);
        }
    }
}

//筛选菜单
const dressingByScreening = (list) => {
    let returnList = []
    list = list.filter(item => {
        return !item.meta.hidden
    })
    for (let i in list) {
        let item = list[i]
        let levegrade = getLevegrade(item.meta.navGroup)
        if (levegrade == 1) {
            returnList.push(item)
        }
    }
    circulation(returnList, list);
    return returnList
}

const state = {
    routerList: dressingByScreening(router.options.routes)
}
const getters = {
    routerList: state => state.routerList,
}

const actions = {
    // 获取用户信息
    getRouterList({
        commit,
        dispatch,
        getters,
        state
    }, res) {
        return new Promise((resolve, reject) => {
            console.log()
            // commit('routerList', res.data);
            resolve()

        })
    },

}
const mutations = {
    routerList(state, payload) {
        state.routerList = payload;
    },
}


export default {
    state,
    mutations,
    actions,
    getters
}
