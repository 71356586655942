import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// title 标题
// languageValue 中英文切换标识
// navGroup 菜单组 1-0-0 一级菜单 1-1-0 二级菜单 1-1-1 三级菜单
// hidden 是否在菜单展示
// img  顶部导航栏图片
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/index.vue'),
    meta: {
      title: '首页',
      languageValue: 'nav.info.indexPageNmae',//语言标识
      navGroup: '1-0-0'
    },
  },
  {
    path: '/hardwareProducts',
    name: 'hardwareProducts',
    redirect: "/hardwareProducts/class/1",
    component: () => import('@/views/hardwareProducts/index.vue'),
    meta: {
      title: '硬件产品',
      languageValue: 'nav.info.onePageNmae',//语言标识
      navGroup: '2-0-0'
    },

  },
  {
    path: '/hardwareProducts/class/1',
    name: 'hardwareProductsClass1',
    component: () => import('@/views/hardwareProducts/class.vue'),
    meta: {
      title: '非机动车充电产品',
      languageValue: 'nav.info.classOne',//语言标识
      navGroup: '2-1-0'
    },
  },
  {
    path: '/hardwareProducts/class/2',
    name: 'hardwareProductsClass2',
    component: () => import('@/views/hardwareProducts/class.vue'),
    meta: {
      title: '非机动车停车产品',
      languageValue: 'nav.info.classTwo',//语言标识
      navGroup: '2-2-0'
    },
  },
  {
    path: '/hardwareProducts/class/3',
    name: 'hardwareProductsClass3',
    component: () => import('@/views/hardwareProducts/class.vue'),
    meta: {
      title: '新能源汽车充电产品',
      languageValue: 'nav.info.classThree',//语言标识
      navGroup: '2-3-0'
    },
  },

  // {
  //   path: '/hardwareProducts/class/4',
  //   name: 'hardwareProductsClass4',
  //   component: () => import('@/views/hardwareProducts/class.vue'),
  //   meta: {
  //     title: '配件产品',
  //     languageValue: 'nav.info.classFour',//语言标识
  //     navGroup: '2-4-0'
  //   },
  // },
  {
    path: '/hardwareProducts/classOne/ordinaryCharge',
    name: 'ordinaryCharge',
    component: () => import('@/views/hardwareProducts/class-1/d-1.vue'),
    meta: {
      title: '双路智能充电桩',
      languageValue: 'nav.info.classOnedeviceOne',//语言标识
      navGroup: '2-1-1',
      //顶部导航栏图片
      img: '/nav/nav-img-1.png'
    },
  },
  {
    path: '/hardwareProducts/classOne/newCharge',
    name: 'newCharge',
    component: () => import('@/views/hardwareProducts/class-1/d-2.vue'),
    meta: {
      title: '双路智能充电桩',
      languageValue: 'nav.info.classOnedeviceTwo',//语言标识
      navGroup: '2-1-2',
      //顶部导航栏图片
      img: '/nav/nav-img-2.png'
    },
  },
  {
    path: '/hardwareProducts/classOne/tenCharge',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-1/d-3.vue'),
    meta: {
      title: '8+2路智能充电桩',
      languageValue: 'nav.info.classOnedeviceThree',//语言标识
      navGroup: '2-1-3',
      //顶部导航栏图片
      img: '/nav/nav-img-3.png'
    },
  },
  {
    path: '/hardwareProducts/classOne/gateway',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-1/d-4.vue'),
    meta: {
      title: 'LTE-LORA工业级网关',
      languageValue: 'nav.info.classOnedeviceFour',//语言标识
      navGroup: '2-1-4',
      //顶部导航栏图片
      img: '/nav/nav-img-4.png'
    },
  },
  {
    path: '/hardwareProducts/classOne/twelveCharge',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-1/d-5.vue'),
    meta: {
      title: '10/12路智能充电桩',
      languageValue: 'nav.info.classOnedeviceFive',//语言标识
      navGroup: '2-1-5',
      //顶部导航栏图片
      img: '/nav/nav-img-20.png'
    },
  },
  {
    path: '/hardwareProducts/classTwo/accessControl',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-2/d-1.vue'),
    meta: {
      title: '网络门禁主机',
      languageValue: 'nav.info.classTowdeviceOne',//语言标识
      navGroup: '2-2-1',
      //顶部导航栏图片
      img: '/nav/nav-img-5.png'
    },
  },
  {
    path: '/hardwareProducts/classTwo/bluetoothAccessControl',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-2/d-2.vue'),
    meta: {
      title: '蓝牙门禁控制器',
      languageValue: 'nav.info.classTowdeviceTwo',//语言标识
      navGroup: '2-2-2',
      //顶部导航栏图片
      img: '/nav/nav-img-6.png'
    },
  },
  {
    path: '/hardwareProducts/classTwo/RFIDReader',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-2/d-3.vue'),
    meta: {
      title: 'RFID读头',
      languageValue: 'nav.info.classTowdeviceThree',//语言标识
      navGroup: '2-2-3',
      //顶部导航栏图片
      img: '/nav/nav-img-7.png'
    },
  },
  {
    path: '/hardwareProducts/classTwo/SGCControl',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-2/d-4.vue'),
    meta: {
      title: 'SGC智能门禁控制器',
      languageValue: 'nav.info.classTowdeviceFour',//语言标识
      navGroup: '2-2-4',
      //顶部导航栏图片
      img: '/nav/nav-img-21.png'
    },
  },
  {
    path: '/hardwareProducts/classThree/Energy',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-3/d-1.vue'),
    meta: {
      title: '7KW单相交流桩(运营版)',
      languageValue: 'nav.info.classThreedeviceOne',//语言标识
      navGroup: '2-3-1',
      //顶部导航栏图片
      img: '/nav/nav-img-8.png'
    },
  },
  {
    path: '/hardwareProducts/classThree/SwipeCardEnergy',
    name: 'tenCharge',
    component: () => import('@/views/hardwareProducts/class-3/d-2.vue'),
    meta: {
      title: '7KW单相交流桩(刷卡版)',
      languageValue: 'nav.info.classThreedeviceTwo',//语言标识
      navGroup: '2-3-2',
      //顶部导航栏图片
      img: '/nav/nav-img-22.png'
    },
  },
  // {
  //   path: '/hardwareProducts/classFour/paymentModule',
  //   name: 'tenCharge',
  //   component: () => import('@/views/hardwareProducts/class-4/d-1.vue'),
  //   meta: {
  //     title: '4G支付模块',
  //     languageValue: 'nav.info.classFourdeviceOne',//语言标识
  //     navGroup: '2-4-1',
  //     //顶部导航栏图片
  //     img: '/nav/nav-img-9.png'
  //   },
  // },
  // {
  //   path: '/hardwareProducts/classFour/openSocket',
  //   name: 'tenCharge',
  //   component: () => import('@/views/hardwareProducts/class-4/d-2.vue'),
  //   meta: {
  //     title: '明装插座',
  //     languageValue: 'nav.info.classFourdeviceTwo',//语言标识
  //     navGroup: '2-4-2',
  //     //顶部导航栏图片
  //     img: '/nav/nav-img-10.png'
  //   },
  // },
  // {
  //   path: '/hardwareProducts/classFour/chargingPileSocketRainCover',
  //   name: 'tenCharge',
  //   component: () => import('@/views/hardwareProducts/class-4/d-3.vue'),
  //   meta: {
  //     title: '充电桩插座防雨罩',
  //     languageValue: 'nav.info.classFourdeviceThree',//语言标识
  //     navGroup: '2-4-3',
  //     //顶部导航栏图片
  //     img: '/nav/nav-img-11.png'
  //   },
  // },
  // {
  //   path: '/hardwareProducts/classFour/icCard',
  //   name: 'tenCharge',
  //   component: () => import('@/views/hardwareProducts/class-4/d-4.vue'),
  //   meta: {
  //     title: 'IC卡',
  //     languageValue: 'nav.info.classFourdeviceFour',//语言标识
  //     navGroup: '2-4-4',
  //     //顶部导航栏图片
  //     img: '/nav/nav-img-12.png'
  //   },
  // },
  {
    path: '/softwareSystem',
    name: 'softwareSystem',
    component: () => import('@/views/softwareSystem/index.vue'),
    meta: {
      title: '软件系统',
      languageValue: 'nav.info.twoPageNmae',//语言标识
      navGroup: '3-0-0'
    },
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import('@/views/cooperate/index.vue'),
    meta: {
      title: '招商合作',
      languageValue: 'nav.info.threePageNmae',//语言标识
      navGroup: '4-0-0'
    },
  },
  {
    path: '/cooperationCases',
    name: 'cooperationCases',
    component: () => import('@/views/cooperationCases/index.vue'),
    meta: {
      title: '合作案例',
      languageValue: 'nav.info.fourPageNmae',//语言标识
      navGroup: '5-0-0'
    },
  },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: () => import('@/views/news/index.vue'),
  //   meta: {
  //     title: '新闻资讯',
  //     languageValue: 'nav.info.fivePageNmae',//语言标识
  //     navGroup: '6-0-0'
  //   },
  // },
  // {
  //   path: '/news/details',
  //   name: 'newDetails',
  //   component: () => import('@/views/news/details.vue'),
  //   meta: {
  //     title: '新闻资讯详情',
  //     hidden: true,//不在菜单展示
  //     navGroup: '6-0-1'
  //   },
  // },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs/index.vue'),
    meta: {
      title: '关于我们',
      languageValue: 'nav.info.sixPageNmae',//语言标识
      navGroup: '7-0-0'
    },
  },



];

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});

export default router;
