/****************合作案例 */
const info = {
    cn: {
        headerPicChTitle: "合作案例",
        headerPicEnTitle: "Cooperation Cases",
        headerPicWord: "200+城市落地、30000＋小区/园区选择了我们",
        siteCases: {
            titleChName: "场地案例",
            titleEnName: "Site Cases",
        },
        cooperativePartner: {
            titleChName: "合作伙伴",
            titleEnName: "Cooperative Partner",
        },
    },
    en: {
        headerPicChTitle: "Cooperation Cases",
        headerPicEnTitle: "合作案例",
        headerPicWord: "200+ cities and 30000+ communities/parks have chosen us",
        siteCases: {
            titleChName: "Site Cases",
            titleEnName: "场地案例",
        },
        cooperativePartner: {
            titleChName: "Cooperative Partner",
            titleEnName: "合作伙伴",
        },

    }
}
export default info