const info = {
  cn: {
    bigTitle1: '快速导航',
    bigTitle2: '联系我们',
    bigTitle3: '关注我们',
    contactPhone: '电话',
    contactEmail: '邮箱',
    contactAddress: '地址',
    address: '成都市高新区天府软件园',
    followUsText1: '云效充小程序',
    followUsText2: '效充小程序',
    copyright: 'Copyright ©2014-2025 版权所有 成都铅笔科技有限公司',
    filings:"蜀ICP备16012568号",
    formText: {
      name: '您的姓名',
      namePlaceholder: '请输入您的名字',
      phone: '您的电话',
      phonePlaceholder: '请输入您的电话',
      select: '您想了解的产品类型(多选)',
      selectValue_1: '电动自行车充电桩',
      selectValue_2: '电动汽车充电桩',
      sub: '提交',
    },
  },
  en: {
    bigTitle1: 'Quick Navigation',
    bigTitle2: 'Contact Us',
    bigTitle3: 'Follow us',
    contactPhone: 'phone',
    contactEmail: 'mailbox',
    contactAddress: 'address',
    address: 'Tianfu Software Park in Chengdu High-tech Zone',
    followUsText1: 'Cloud Effect Charging Mini Program',
    followUsText2: 'Charging mini program',
    copyright: 'Copyright ©2014-2025 版权所有 成都铅笔科技有限公司',
    filings:"蜀ICP备16012568号",
    formText: {
      name: 'Name',
      namePlaceholder: 'Please enter your first name',
      phone: 'Your phone number',
      phonePlaceholder: 'Please enter your phone number',
      select: 'The type of product you want to know (multiple choices)',
      selectValue_1: 'Electric bicycle charging station',
      selectValue_2: 'Electric vehicle charging station',
      sub: 'submit',
    },
  }
}
export default info