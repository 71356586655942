const info = {
    cn: {
        companyName: "铅笔科技",
        indexPageNmae: "首页",
        onePageNmae: "硬件产品",
        twoPageNmae: "软件系统",
        threePageNmae: "招商合作",
        fourPageNmae: "合作案例",
        fivePageNmae: "新闻资讯",
        sixPageNmae: "关于我们",
        classOne: '非机动车充电产品',
        classTwo: '非机动车停车产品',
        classThree: '新能源汽车充电产品',
        classFour:"配件产品",
        classOnedeviceOne: '双路智能充电桩',
        classOnedeviceTwo: '双路智能充电桩',
        classOnedeviceThree: '8+2路智能充电桩',
        classOnedeviceFour: 'LTE-LORA工业级网关',
        classOnedeviceFive:'10/12路智能充电桩',
        classTowdeviceOne: '网络门禁主机',
        classTowdeviceTwo: '蓝牙门禁控制器',
        classTowdeviceThree: 'RFID读头',
        classTowdeviceFour:'SGC智能门禁控制器',
        classThreedeviceOne: '7KW单相交流桩(运营版)',
        classThreedeviceTwo:'7KW单相交流桩(刷卡版)',
        classFourdeviceOne: '4G支付模块',
        classFourdeviceTwo:'明装插座',
        classFourdeviceThree:'充电桩插座防雨罩',
        classFourdeviceFour:'IC卡',
        zhLang: 'CN',
        enLang: 'EN',
        adminText: '商户运营平台'


    },
    en: {
        companyName: "Chainbee",
        indexPageNmae: "HomePage",
        onePageNmae: "HardwareProducts",
        twoPageNmae: "SystemsSoftware",
        threePageNmae: "InvestmentCooperation",
        fourPageNmae: "CooperationCases",
        fivePageNmae: "News",
        sixPageNmae: "AboutUs",
        classOne: 'Non motor vehicle charging products',
        classTwo: 'Non motor vehicle parking products',
        classThree: 'New energy vehicle charging products',
        classFour:"Accessory products",
        classOnedeviceOne: 'DualIntelligent charging station',
        classOnedeviceTwo: 'DualIntelligent charging station',
        classOnedeviceThree: '8+2 Intelligent charging stations',
        classOnedeviceFour: 'LTE-LORA industrial gateway',
        classOnedeviceFive:'10/12 Intelligent charging stations',
        classTowdeviceOne: 'Network access control host',
        classTowdeviceTwo: 'Bluetooth access controller',
        classTowdeviceThree: 'RFID reader',
        classTowdeviceFour:'SGC intelligent access control controller',
        classThreedeviceOne: '7KW single intersecting flowPile (operational version)',
        classThreedeviceTwo: '7KW single intersecting flowPile (swipe card version)',
        classFourdeviceOne: '4G payment module',
        classFourdeviceTwo:'Open socket',
        classFourdeviceThree:'Charging pile socket rain cover',
        classFourdeviceFour:'IC card',
        zhLang: 'CN',
        enLang: 'EN',
        adminText: 'Platform'



    }
}
export default info