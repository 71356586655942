/** ************** */
const info = {
  cn: {
    headTitle: {
      title_1: '招商合作',
      title_2: 'lnvestment promotion and cooperation',
      title_3: '招募加盟商、合作商、城市合伙人',
    },
    companyProfile: {
      title_1: '合作模式',
      title_2: 'Cooperation mode',
    },
    modeList: [
      {
        title: '设备购买',
        info: '合作商采购设备，投放运营，获取全部营收，快速回笼资金，物业资源快速变现。',
        img: '/cooperate/mode-1.png',
      },
      {
        title: '合作分成',
        info: '厂家免费投放设备、运维、系统，合作方提供场地支出后，所得收益双方进行分成。',
        img: '/cooperate/mode-2.png',
      },
      {
        title: '设备租赁',
        info: '厂家免费投放设备、系统，合作方提供场地及运营，场地营收与支出归合作方，合作方每月向厂家支付设备租赁费。',
        img: '/cooperate/mode-3.png',
      },
      {
        title: '代理销售',
        info: '代理商可向厂家采购设备，转卖设备赚取差价。也可提供第三方信息促成销售合同签订，赚取销售提成。',
        img: '/cooperate/mode-4.png',
      },
    ],
    getMoneyType: {
      titleChName: '多种收益方式，不再只依靠停车/充电费用变现',
      titleEnName: 'Multiple income methods, no longer relying solely on parking/charging fees to realize',
    },
    getMoneyTypeList: [
      {
        title: '停车消费收益',
        info: '通过收取停车费进行收益',
        img: '/cooperate/getMoneyType-1.png',
      },
      {
        title: '充电消费/充值收益',
        info: '通过电费差价进行收益',
        img: '/cooperate/getMoneyType-2.png',
      },
      {
        title: '平台服务费分成收益',
        info: '发展二级运营商，获得运营商平台服务费分成',
        img: '/cooperate/getMoneyType-3.png',
      },
      {
        title: '充电保险收益',
        info: '收取充电保险费用',
        img: '/cooperate/getMoneyType-4.png',
      },
      {
        title: '增值服务收益',
        info: '小程序广告推送，与广告商合作，广告利益分成',
        img: '/cooperate/getMoneyType-5.png',
      },
    ],
    OEM: {
      titleChName: 'OEM/ODM定制化服务',
      titleEnName: 'OEM/ODM customized services',
    },
    OEMList: ['支持独立设计定制外观', '支持绑定微信公众号/小程序', '支持独立部署云服务器', '资金实时到账运营商账户', '提供技术服务、运维服务、售后服务', '提供硬件通信协议或API接口'],
    certificate: {
      titleChName: '设备、系统自主研发',
      titleEnName: 'Independent research and development of equipment and systems',
    },
    sustain: {
      titleChName: '我们可提供多方位指导',
      titleEnName: 'We can provide multi-directional guidance',
    },
    sustainList: [
      {
        title: '宣传物料发放',
        info: '免费提供各种宣传物料、海报',
        img: '/cooperate/sustain-1.png',
      },
      {
        title: '免费指导市场开拓',
        info: '进行合作模式分析，盈利测算的指导，定期组织系统培训',
        img: '/cooperate/sustain-2.png',
      },
      {
        title: '设备免费维修',
        info: '提供设备2年质保与维修服务',
        img: '/cooperate/sustain-3.png',
      },
      {
        title: '代运营 代客服',
        info: '提供场地代运营，代客服服务',
        img: '/cooperate/sustain-4.png',
      },
    ],
    step: {
      titleChName: '加盟合作运营流程',
      titleEnName: 'Franchise cooperation operation process',
    },
    stepList: [
      {
        title: '加盟运营流程',
        list: [
          {
            img: '/cooperate/step-1.png',
            title: '01/ 市场沟通',
          }, {
            img: '/cooperate/step-2.png',
            title: '02/ 合同签署',
          },
          {
            img: '/cooperate/step-3.png',
            title: '03/ 设备发货',
          }, {
            img: '/cooperate/step-4.png',
            title: '04/ 安装上线',
          },
          {
            img: '/cooperate/step-5.png',
            title: '05/ 系统培训',
          },
          {
            img: '/cooperate/step-6.png',
            title: '06/ 运营指导',
          },
        ],

      },
      {
        title: '合作运营流程',
        list: [
          {
            img: '/cooperate/step-7.png',
            title: '01/ 项目沟通洽谈',
          }, {
            img: '/cooperate/step-8.png',
            title: '02/ 签订投资协议',
          },
          {
            img: '/cooperate/step-9.png',
            title: '03/ 确定分润比例',
          }, {
            img: '/cooperate/step-10.png',
            title: '04/ 设备投放使用',
          },
          {
            img: '/cooperate/step-11.png',
            title: '05/ 设备上线运营',
          },

        ],

      },
    ],
    formTitle: {
      titleChName: '获取投资方案',
      titleEnName: 'Obtain investment plans',
    },
    formText: {
      name: '您的姓名',
      namePlaceholder: '请输入您的名字',
      phone: '您的电话',
      phonePlaceholder: '请输入您的电话',
      select: '您想了解的产品类型(多选)',
      selectValue_1: '电动自行车充电桩',
      selectValue_2: '电动汽车充电桩',
      sub: '提交',
    },
  },
  en: {
    headTitle: {
      title_1: 'lnvestment promotion and cooperation',
      title_2: '招商合作',
      title_3: 'Recruiting franchisees, partners, and city partners',
    },
    companyProfile: {
      title_1: 'Cooperation mode',
      title_2: '合作模式',
    },
    modeList: [
      {
        title: 'Equipment purchase',
        info: 'Partners purchase equipment, launch operations, obtain full revenue, quickly recover funds, and quickly monetize property resources.',
        img: '/cooperate/mode-1.png',
      },
      {
        title: 'share revenue',
        info: 'The manufacturer provides free equipment, operation and maintenance, and systems. After the partner provides the venue expenses, the profits will be shared between both parties.',
        img: '/cooperate/mode-2.png',
      },
      {
        title: 'Equipment leasing',
        info: 'The manufacturer provides free equipment and systems, and the partner provides the venue and operation. The venue revenue and expenses belong to the partner, and the partner pays the equipment rental fee to the manufacturer on a monthly basis.',
        img: '/cooperate/mode-3.png',
      },
      {
        title: 'Sales agent',
        info: 'Agents can purchase equipment from manufacturers and resell the equipment to earn a price difference. Third party information can also be provided to facilitate the signing of sales contracts and earn sales commissions.',
        img: '/cooperate/mode-4.png',
      },
    ],
    getMoneyType: {
      titleChName: 'Multiple income methods, no longer relying solely on parking/charging fees to realize',
      titleEnName: '多种收益方式，不再只依靠停车/充电费用变现',
    },
    getMoneyTypeList: [
      {
        title: 'Parking consumption income',
        info: 'Revenue through charging parking fees',
        img: '/cooperate/getMoneyType-1.png',
      },
      {
        title: 'Charge consumption/recharge income',
        info: 'Earnings through electricity price differentials',
        img: '/cooperate/getMoneyType-2.png',
      },
      {
        title: 'Platform service fee sharing income',
        info: 'Develop secondary operators and obtain service fee sharing from operator platforms',
        img: '/cooperate/getMoneyType-3.png',
      },
      {
        title: 'Charging insurance income',
        info: 'Charging insurance fees',
        img: '/cooperate/getMoneyType-4.png',
      },
      {
        title: 'Value added service revenue',
        info: 'Mini program advertising push, collaborate with advertisers, and share advertising benefits',
        img: '/cooperate/getMoneyType-5.png',
      },
    ],
    OEM: {
      titleChName: 'OEM/ODM customized services',
      titleEnName: 'OEM/ODM定制化服务',
    },
    OEMList: ['Support for independent design and customized appearance', 'Support binding wechat public account/small program', 'Supports independent deployment of cloud servers', 'Real time transfer of funds to operator account', 'Provide technical services, operation and maintenance services, and after-sales services', 'Provide hardware communication protocol or API interface'],
    certificate: {
      titleChName: 'Independent research and development of equipment and systems',
      titleEnName: '设备、系统自主研发',
    },
    sustain: {
      titleChName: 'We can provide multi-directional guidance',
      titleEnName: '我们可提供多方位指导',
    },
    sustainList: [
      {
        title: 'Distribution of promotional materials',
        info: 'Provide various promotional materials and posters for free',
        img: '/cooperate/sustain-1.png',
      },
      {
        title: 'Free guidance on market development',
        info: 'Conduct cooperation model analysis, provide guidance on profit calculation, and regularly organize systematic training',
        img: '/cooperate/sustain-2.png',
      },
      {
        title: 'Free maintenance of equipment',
        info: 'Provide 2-year warranty and maintenance services for equipment',
        img: '/cooperate/sustain-3.png',
      },
      {
        title: 'Agent operation and customer service',
        info: 'Provide venue agency operation and customer service',
        img: '/cooperate/sustain-4.png',
      },
    ],
    step: {
      titleChName: 'Franchise cooperation operation process',
      titleEnName: '加盟合作运营流程',
    },
    stepList: [
      {
        title: 'Franchise operation process',
        list: [
          {
            img: '/cooperate/step-1.png',
            title: '01/ marketing communication',
          }, {
            img: '/cooperate/step-2.png',
            title: '02/ contract signing',
          },
          {
            img: '/cooperate/step-3.png',
            title: '03/ Equipment shipment',
          }, {
            img: '/cooperate/step-4.png',
            title: '04/ Install and go live',
          },
          {
            img: '/cooperate/step-5.png',
            title: '05/ system training',
          },
          {
            img: '/cooperate/step-6.png',
            title: '06/ Operational guidance',
          },
        ],

      },
      {
        title: 'Cooperative operation process',
        list: [
          {
            img: '/cooperate/step-7.png',
            title: '01/ Project communication and negotiation',
          }, {
            img: '/cooperate/step-8.png',
            title: '02/ Sign investment agreement',
          },
          {
            img: '/cooperate/step-9.png',
            title: '03/ Determine the profit sharing ratio',
          }, {
            img: '/cooperate/step-10.png',
            title: '04/ Equipment placement and use',
          },
          {
            img: '/cooperate/step-11.png',
            title: '05/ Equipment online operation',
          },

        ],

      },
    ],
    formTitle: {
      titleChName: 'Obtain investment plans',
      titleEnName: '获取投资方案',
    },
    formText: {
      name: 'Name',
      namePlaceholder: 'Please enter your first name',
      phone: 'Your phone number',
      phonePlaceholder: 'Please enter your phone number',
      select: 'The type of product you want to know (multiple choices)',
      selectValue_1: 'Electric bicycle charging station',
      selectValue_2: 'Electric vehicle charging station',
      sub: 'submit',
    },
  },
};
export default info;
