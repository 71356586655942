/*******************软件系统 */
const info = {
    cn: {
        headerPicChTitle: "软件系统",
        headerPicEnTitle: "Software System",
        headerPicWord: "用户使用平台、商户运营平台",
        userServicePlatform: {
            titleChName: "用户服务平台",
            titleEnName: "User service platform",
            info: "以微信小程序为主要入口，为用户提供在线支付、停车状态查询、充电状态查询、卡片信息等服务，具备 在线充值缴费、自主办理卡片、结束订单、账户余额查询等功能，给用户带来更加便捷的停车充电体验",
            picList: [
                {
                    title: "停车/充电",
                    info: "支持扫码、刷卡进行停车/充电",
                    pic: "softwareSystemUserServicePlatformS1.png"
                },
                {
                    title: "充值缴费",
                    info: "用户可自主或帮助他人卡片进行充值缴费。",
                    pic: "softwareSystemUserServicePlatformS2.png"
                },
                {
                    title: "订单查询",
                    info: "查看停车、充电订单进度，对正在进行中订单进行结束。",
                    pic: "softwareSystemUserServicePlatformS3.png"
                },
                {
                    title: "卡片查询",
                    info: "可进行储值卡余额、包月卡到期日期查询",
                    pic: "softwareSystemUserServicePlatformS4.png"
                },
                {
                    title: "自主办理卡片",
                    info: "可自主/帮他人办理电子包月卡、电子充电卡、电子充值卡",
                    pic: "softwareSystemUserServicePlatformS5.png"
                },
            ]
        },
        operatorManagementPlatform: {
            titleChName: "运营商管理平台",
            titleEnName: "Operator management platform",
            info: "可通过PC端/小程序端进行设备管理、用户管理、站点管理、收费管理等。 支持各类数据查询。支持场地智能化系统化全方面运营",
            picList: [
                {
                    title: "设备管理",
                    info: "设备列表、设备控制、参数设置、设备使用记录查看等。",
                    pic: "operatorManagementPlatformSPic1.png"
                },
                {
                    title: "用户管理",
                    info: "用户列表、充值缴费、用户状态修改、更改卡号/手机号",
                    pic: "operatorManagementPlatformSPic2.png"
                },
                {
                    title: "费用/充值赠送配置",
                    info: "包月停车、临时停车、包月充电、临时充电、充值赠送",
                    pic: "operatorManagementPlatformSPic3.png"
                },
                {
                    title: "车位管理",
                    info: "车位列表、专属充电插座管理、车位用户管理",
                    pic: "operatorManagementPlatformSPic4.png"
                },
                {
                    title: "记录查询",
                    info: "停车/充电缴费记录、充值记录、停车记录、充电记录等",
                    pic: "operatorManagementPlatformSPic5.png"
                },
            ]
        },
    },
    en: {
        headerPicChTitle: "Software System",
        headerPicEnTitle: "软件系统",
        headerPicWord: "Users use the platform and merchants operate the platform",
        userServicePlatform: {
            titleChName: "User service platform",
            titleEnName: "用户服务平台",
            info: "Taking wechat mini program as the main entrance, it provides users with online payment, parking status query, charging status query, card information and other services. It has functions such as online recharge and payment, independently handle cards, end orders, and account balance query, which brings users a more convenient parking and charging experience.",
            picList: [
                {
                    title: "Parking/charging",
                    info: "Support scanning code, swipe card for parking/charging",
                    pic: "softwareSystemUserServicePlatformS1.png"
                },
                {
                    title: "Recharge and pay",
                    info: "Users can recharge their own cards or help others to pay fees.",
                    pic: "softwareSystemUserServicePlatformS2.png"
                },
                {
                    title: "Order inquiry",
                    info: "Check the progress of parking and charging orders, and terminate the orders in progress.",
                    pic: "softwareSystemUserServicePlatformS3.png"
                },
                {
                    title: "Card query",
                    info: "Can be stored value card balance, monthly card expiration date query",
                    pic: "softwareSystemUserServicePlatformS4.png"
                },
                {
                    title: "Self-processing cards",
                    info: "Can independently/help others to handle electronic monthly card, electronic charge card, electronic recharge card.",
                    pic: "softwareSystemUserServicePlatformS5.png"
                },
            ]
        },
        operatorManagementPlatform: {
            titleChName: "Operator management platform",
            titleEnName: "运营商管理平台",
            info: "Device management, user management, site management, charge management. can be carried out through the PC side/small program side. Support all kinds of data query. Support all aspects of intelligent and systematic operation of the site.",
            picList: [
                {
                    title: "Equipment management",
                    info: "Device list, device control, parameter setting, device usage record view",
                    pic: "operatorManagementPlatformSPic1.png"
                },
                {
                    title: "User management",
                    info: "User list, recharge payment, user status modification, card number/mobile phone number change",
                    pic: "operatorManagementPlatformSPic2.png"
                },
                {
                    title: "Fee/top-up free configuration",
                    info: "Monthly parking, temporary parking, monthly charging, temporary charging, recharge free",
                    pic: "operatorManagementPlatformSPic3.png"
                },
                {
                    title: "Parking management",
                    info: "Parking list, exclusive charging socket management, parking user management",
                    pic: "operatorManagementPlatformSPic4.png"
                },
                {
                    title: "Record query",
                    info: "Parking/charging fee record, recharge record, parking record, charging record",
                    pic: "operatorManagementPlatformSPic5.png"
                },
            ]
        },
    }
}
export default info