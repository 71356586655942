
// 中英文切换使用
const state = () => ({
    langeStyle: localStorage.getItem('lang') || 'cn'
});

const getters = {
    language: state => state.langeStyle,
}
// actions
const actions = {
};

// mutations
const mutations = {
    setLangeStyle(state, data) {
        state.langeStyle = data;
        localStorage.setItem('lang', data)

    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
