/**********************硬件产品 */
import { imgUrl } from '@/config/env';
const info = {
    cn: {
        dco: {
            title: "双路智能充电桩",
            tabList: ['4G/LORA', '刷卡+扫码'],
            content: "交流输出电源远程通断控制、充电安全控制、电量计费、按时计费功能于一体的供电装置。支持两轮三 轮车、大功率三轮车、运营车、四轮代步车等不同车辆类型充电。具备灵活支付、充满自停、过载保护 等功能，支付方式可选择刷卡、扫码使用。",
            otherObj: {
                ms: '通讯方式为LORA的双路智能充电桩需搭配充电网关使用',
                type: 1,
                list: [{
                    title: 'LTE-LORA工业级网关',
                    img: `${imgUrl}/device/charge/d-header-other-1.png`,
                    path: '/hardwareProducts/classOne/gateway'
                }]
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/charge/feature1.png",
                    name: "卡片储值"
                },
                {
                    icon: "/device/charge/feature2.png",
                    name: "电量计费"
                }, {
                    icon: "/device/charge/feature3.png",
                    name: "充电进度"
                }, {
                    icon: "/device/charge/feature4.png",
                    name: "过载保护"
                }, {
                    icon: "/device/charge/feature5.png",
                    name: "刷卡支付"
                }, {
                    icon: "/device/charge/feature6.png",
                    name: "离网运行"
                }, {
                    icon: "/device/charge/feature7.png",
                    name: "语音提示"
                }, {
                    icon: "/device/charge/feature8.png",
                    name: "包月充电"
                }, {
                    icon: "/device/charge/feature9.png",
                    name: "微信支付"
                }, {
                    icon: "/device/charge/feature10.png",
                    name: "异常检测"
                }, {
                    icon: "/device/charge/feature11.png",
                    name: "临时充电"
                }, {
                    icon: "/device/charge/feature12.png",
                    name: "车位绑定"
                }, {
                    icon: "/device/charge/feature13.png",
                    name: "远程控制"
                }, {
                    icon: "/device/charge/feature14.png",
                    name: "断电续充"
                }, {
                    icon: "/device/charge/feature15.png",
                    name: "时间计费"
                }, {
                    icon: "/device/charge/feature16.png",
                    name: "充满自停"
                },
            ],
            parametersTableInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersTableList: [
                {
                    title: "产品型号",
                    type: "text",
                    infoOne: [
                        { info: "C2" }
                    ],
                    infoTwo: [
                        { info: "C2" }
                    ],
                    infoThree: [
                        { info: "C2" }
                    ]
                },
                {
                    title: "产品规格",
                    type: "text",
                    infoOne: [
                        { info: "小功率（1.0KW-4G）" },
                        { info: "小功率（1.0KW-LORA）" },

                    ],
                    infoTwo: [
                        { info: "高功率（2.2KW-4G）" },
                        { info: "高功率（2.2KW-LORA）" },
                    ],
                    infoThree: [
                        { info: "大功率（3.5KW-4G）" },
                        { info: "大功率（3.5KW-LORA）" },
                    ]
                },
                {
                    title: "插座数量",
                    type: "text",
                    infoOne: [
                        { info: "2个" },


                    ],
                    infoTwo: [
                        { info: "2个" },

                    ],
                    infoThree: [
                        { info: "2个" },
                    ]
                },
                {
                    title: "产品尺寸",
                    type: "text",
                    infoOne: [
                        { info: "238mm*115mm*80.6mm" },

                    ],
                    infoTwo: [
                        { info: "238mm*115mm*80.6mm" },

                    ],
                    infoThree: [
                        { info: "238mm*115mm*80.6mm" },

                    ]
                },
                {
                    title: "产品颜色",
                    type: "image",
                    infoOne: [
                        { info: "/device/charge/write.png" },
                        { info: "/device/charge/orange.png" },
                        { info: "/device/charge/green.png" },


                    ],
                    infoTwo: [
                        { info: "/device/charge/write.png" },
                        { info: "/device/charge/orange.png" },
                        { info: "/device/charge/green.png" },
                    ],
                    infoThree: [
                        { info: "/device/charge/write.png" },
                        { info: "/device/charge/orange.png" },
                        { info: "/device/charge/green.png" },
                    ]
                },
                {
                    title: "语音播报",
                    type: "text",
                    infoOne: [
                        { info: "支持" },


                    ],
                    infoTwo: [
                        { info: "支持" },

                    ],
                    infoThree: [
                        { info: "支持" },
                    ]
                },
                {
                    title: "计费模式",
                    type: "text",
                    infoOne: [
                        { info: "时长、电量、功率、包月等方式" },


                    ],
                    infoTwo: [
                        { info: "时长、电量、功率、包月等方式" },

                    ],
                    infoThree: [
                        { info: "时长、电量、功率、包月等方式" },
                    ]
                },
                {
                    title: "用户使用",
                    type: "text",
                    infoOne: [
                        { info: "扫码充电、刷卡充电、卡片充值" },


                    ],
                    infoTwo: [
                        { info: "扫码充电、刷卡充电、卡片充值" },

                    ],
                    infoThree: [
                        { info: "扫码充电、刷卡充电、卡片充值" },
                    ]
                },
                {
                    title: "安全防护",
                    type: "text",
                    infoOne: [
                        { info: "过载、过压、过充" },
                    ],
                    infoTwo: [
                        { info: "过载、过压、过充" },

                    ],
                    infoThree: [
                        { info: "过载、过压、过充" },
                    ]
                },
                {
                    title: "LED指示",
                    type: "text",
                    infoOne: [
                        { info: "双色LED灯" },
                    ],
                    infoTwo: [
                        { info: "双色LED灯" },

                    ],
                    infoThree: [
                        { info: "双色LED灯" },
                    ]
                },
                {
                    title: "通信方式",
                    type: "text",
                    infoOne: [
                        { info: "4G/LORA" },
                    ],
                    infoTwo: [
                        { info: "4G/LORA" },

                    ],
                    infoThree: [
                        { info: "4G/LORA" },
                    ]
                },
                {
                    title: "输出口数",
                    type: "text",
                    infoOne: [
                        { info: "双口输出、独立控制" },
                    ],
                    infoTwo: [
                        { info: "双口输出、独立控制" },

                    ],
                    infoThree: [
                        { info: "双口输出、独立控制" },
                    ]
                },
                {
                    title: "最大输出功率",
                    type: "text",
                    infoOne: [
                        { info: "1000W" },
                    ],
                    infoTwo: [
                        { info: "2200W" },

                    ],
                    infoThree: [
                        { info: "3500W" },
                    ]
                },
                {
                    title: "电能计量",
                    type: "text",
                    infoOne: [
                        { info: "每个充电口独立计量" },
                    ],
                    infoTwo: [
                        { info: "每个充电口独立计量" },

                    ],
                    infoThree: [
                        { info: "每个充电口独立计量" },
                    ]
                },
                {
                    title: "输入电压",
                    type: "text",
                    infoOne: [
                        { info: "AC 220 ±10% 50Hz" },
                    ],
                    infoTwo: [
                        { info: "AC 220 ±10% 50Hz" },

                    ],
                    infoThree: [
                        { info: "AC 220 ±10% 50Hz" },
                    ]
                },
                {
                    title: "输出电压",
                    type: "text",
                    infoOne: [
                        { info: "AC 220 ±10% 50Hz" },
                    ],
                    infoTwo: [
                        { info: "AC 220 ±10% 50Hz" },

                    ],
                    infoThree: [
                        { info: "AC 220 ±10% 50Hz" },
                    ]
                },
                {
                    title: "单路最大电流",
                    type: "text",
                    infoOne: [
                        { info: "6A" },
                    ],
                    infoTwo: [
                        { info: "10A" },

                    ],
                    infoThree: [
                        { info: "16A" },
                    ]
                },
                {
                    title: "典型功率",
                    type: "text",
                    infoOne: [
                        { info: "＜3W" },
                    ],
                    infoTwo: [
                        { info: "＜3W" },

                    ],
                    infoThree: [
                        { info: "＜3W" },
                    ]
                },
                {
                    title: "供电要求",
                    type: "text",
                    infoOne: [
                        { info: "漏电保护器、避雷" },
                    ],
                    infoTwo: [
                        { info: "漏电保护器、避雷" },

                    ],
                    infoThree: [
                        { info: "漏电保护器、避雷" },
                    ]
                },
                {
                    title: "适应场地",
                    type: "text",
                    infoOne: [
                        { info: "室内、室外" },
                    ],
                    infoTwo: [
                        { info: "室内、室外" },

                    ],
                    infoThree: [
                        { info: "室内、室外" },
                    ]
                },
                {
                    title: "工作温度",
                    type: "text",
                    infoOne: [
                        { info: "-20℃~60℃" },
                    ],
                    infoTwo: [
                        { info: "-20℃~60℃" },

                    ],
                    infoThree: [
                        { info: "-20℃~60℃" },
                    ]
                },
                {
                    title: "工作湿度",
                    type: "text",
                    infoOne: [
                        { info: "＜90%不凝露" },
                    ],
                    infoTwo: [
                        { info: "＜90%不凝露" },

                    ],
                    infoThree: [
                        { info: "＜90%不凝露" },
                    ]
                },
                {
                    title: "环境要求",
                    type: "text",
                    infoOne: [
                        { info: "不可浸水，保持通风，信号优良" },
                    ],
                    infoTwo: [
                        { info: "不可浸水，保持通风，信号优良" },

                    ],
                    infoThree: [
                        { info: "不可浸水，保持通风，信号优良" },
                    ]
                },

            ],
            advantageInfo: {
                titleChName: "产品优势",
                titleEnName: "Product Advantages"
            },
            advantageList: [
                {
                    icon: "/device/charge/advantage1.png",
                    title: "使用车群广",
                    info: "支持两轮电动车、大功率三轮车、运营车、四轮代步车等不同车型充电。"
                },
                {
                    icon: "/device/charge/advantage2.png",
                    title: "使用环境广",
                    info: "创新采用分离式智能管控，可远程开电，地下室等无信号要覆盖区域也能轻松搞定。"
                },
                {
                    icon: "/device/charge/advantage3.png",
                    title: "使用人群广",
                    info: "微信扫码支付无需投币，同时支持刷卡充电，解决老人和无智能手机人群充电问题。"
                },

            ],
            displayBottomInfo: {
                bPic: "/device/charge/pDisplayBig.png",
                bTitle: "安装场景包含设备、配件如下",
                bList: [' 1、双路智能充电桩', '2、LTE-LORA工业级网关', ' 3、智能烟感、悬挂式干粉灭火器、4G/5G摄像机']
            },
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
        },
        eat: {
            title: '8+2路智能充电桩',
            tabList: ['4G/LORA', '2200W*8路+3500W*2路', '刷卡+扫码'],
            content: "交流输出电源远程通断控制、充电安全控制、电量计费、按时计费功能于一体的供电装置。支持普通二轮车、三轮车、老年代步车、运营三轮车充电。单路输出功率可达3500W。具备灵活支付、充满自停、过载保护等功能，支付方式可选择刷卡、扫码使用。",
            otherObj: {
                ms: '配套插座需单独购买',
                type: 1,
                list: [
                    {
                        title: '暗装插座',
                        img: `${imgUrl}/device/charge/Concealedsocket.png`,
                        path: ''
                    },
                    {
                        title: '明装插座',
                        img: `${imgUrl}/device/charge/Opensocket.png`,
                        path: '/hardwareProducts/classFour/openSocket'
                    }
                ]
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/charge/feature1.png",
                    name: "卡片储值"
                },
                {
                    icon: "/device/charge/feature2.png",
                    name: "电量计费"
                }, {
                    icon: "/device/charge/feature3.png",
                    name: "充电进度"
                }, {
                    icon: "/device/charge/feature4.png",
                    name: "过载保护"
                }, {
                    icon: "/device/charge/feature5.png",
                    name: "刷卡支付"
                }, {
                    icon: "/device/charge/feature17.png",
                    name: "消息提醒"
                }, {
                    icon: "/device/charge/feature7.png",
                    name: "语音提示"
                }, {
                    icon: "/device/charge/feature8.png",
                    name: "包月充电"
                }, {
                    icon: "/device/charge/feature9.png",
                    name: "微信支付"
                }, {
                    icon: "/device/charge/feature10.png",
                    name: "异常检测"
                }, {
                    icon: "/device/charge/feature11.png",
                    name: "临时充电"
                }, {
                    icon: "/device/charge/feature12.png",
                    name: "车位绑定"
                }, {
                    icon: "/device/charge/feature13.png",
                    name: "远程控制"
                }, {
                    icon: "/device/charge/feature14.png",
                    name: "断电续充"
                }, {
                    icon: "/device/charge/feature15.png",
                    name: "时间计费"
                }, {
                    icon: "/device/charge/feature16.png",
                    name: "充满自停"
                },
            ],
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "8+2路充电桩"
                },
                {
                    title: "产品型号",
                    info: "C10"
                }, {
                    title: "插座数量",
                    info: "10个"
                }, {
                    title: "产品尺寸",
                    info: "330mm*210mm*85mm"
                }, {
                    title: "产品颜色",
                    info: "蓝色"
                }, {
                    title: "语音播报",
                    info: "支持"
                }, {
                    title: "计费方式",
                    info: "时长、电量、功率、包月等方式"
                }, {
                    title: "用户使用",
                    info: "扫码充电、刷卡充电、卡片充值"
                }, {
                    title: "安全防护",
                    info: "过载、过压、过充"
                }, {
                    title: "LED指示灯",
                    info: "双色LED指示灯"
                },
                {
                    title: "通信方式",
                    info: "4G/LORA"
                },
                {
                    title: "输出口数",
                    info: "10口输出、独立控制"
                },

                {
                    title: "输出最大功率",
                    info: "14KW(63A),2200W*8路+3500W*2路"
                }, {
                    title: "电能计量",
                    info: "每个充电口独立计量"
                }, {
                    title: "输入电压",
                    info: "AC 220V ±10% 50Hz"
                }, {
                    title: "输出电压",
                    info: "AC 220V ±10% 50Hz"
                },

                {
                    title: "单路最大电流",
                    info: "16A"
                }, {
                    title: "典型功率",
                    info: "＜8W"
                }, {
                    title: "供电要求",
                    info: "漏电保护器、避雷"
                }, {
                    title: "适应场地",
                    info: "室内、室外"
                }, {
                    title: "工作温度",
                    info: "-20℃~60℃"
                }, {
                    title: "工作湿度",
                    info: "＜90%不凝露"
                }, {
                    title: "环境要求",
                    info: "不可浸水、保持通风、信号优良"
                }, {
                    title: "",
                    info: ""
                },
            ],
            advantageInfo: {
                titleChName: "产品优势",
                titleEnName: "Product Advantages"
            },
            advantageList: [
                {
                    icon: "/device/charge/advantage1.png",
                    title: "使用车群广",
                    info: "采用大小功率混合方案，含8路支持功率2200W端口，及2路支持功率3500W端口，解决两轮电动车、三轮车、老年代步车、运营三轮车等不同车群的充电问题。"
                },
                {
                    icon: "/device/charge/advantage2.png",
                    title: "使用环境广",
                    info: "创新采用分离式智能管控，可远程开电。支持室内、室外、地下室等区域安装使用。"
                },
                {
                    icon: "/device/charge/advantage3.png",
                    title: "使用人群广",
                    info: "微信扫码支付无需投币，同时支持刷卡充电，解决老人和无智能手机人群充电问题。"
                },

            ],
            displayBottomInfo: {
                bPic: "/device/charge/pDisplayTenBig.png",
                bTitle: "安装场景包含设备、配件如下",
                bList: ['1、8+2路智能充电桩', '2、明装5孔插座', ' 3、智能烟感、悬挂式干粉灭火器、4G/5G摄像机']
            },
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
        },
        ticp:{
            title: '10/12 路智能充电桩',
            tabList: ['4G/LORA', '刷卡+扫码'],
            content: "交流输出电源远程通断控制、充电安全控制、电量计费、按时计费功能于一体的供电装置。支持普通二轮车、三轮车、老年代步车、运营三轮车充电。单路输出功率可达2200W。具备灵活支付、充满自停、过载保护等功能，支付方式可选择刷卡、扫码使用。",
            otherObj: {
                ms: '配套插座需单独购买',
                type: 1,
                list: [
                    {
                        title: '暗装插座',
                        img: `${imgUrl}/device/charge/Concealedsocket.png`,
                        path: ''
                    },
                    {
                        title: '明装插座',
                        img: `${imgUrl}/device/charge/Opensocket.png`,
                        path: '/hardwareProducts/classFour/openSocket'
                    }
                ]
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/charge/feature1.png",
                    name: "卡片储值"
                },
                {
                    icon: "/device/charge/feature2.png",
                    name: "电量计费"
                }, {
                    icon: "/device/charge/feature3.png",
                    name: "充电进度"
                }, {
                    icon: "/device/charge/feature4.png",
                    name: "过载保护"
                }, {
                    icon: "/device/charge/feature5.png",
                    name: "刷卡支付"
                }, {
                    icon: "/device/charge/feature17.png",
                    name: "消息提醒"
                }, {
                    icon: "/device/charge/feature7.png",
                    name: "语音提示"
                }, {
                    icon: "/device/charge/feature8.png",
                    name: "包月充电"
                }, {
                    icon: "/device/charge/feature9.png",
                    name: "微信支付"
                }, {
                    icon: "/device/charge/feature10.png",
                    name: "异常检测"
                }, {
                    icon: "/device/charge/feature11.png",
                    name: "临时充电"
                }, {
                    icon: "/device/charge/feature12.png",
                    name: "车位绑定"
                }, {
                    icon: "/device/charge/feature13.png",
                    name: "远程控制"
                }, {
                    icon: "/device/charge/feature14.png",
                    name: "断电续充"
                }, {
                    icon: "/device/charge/feature15.png",
                    name: "时间计费"
                }, {
                    icon: "/device/charge/feature16.png",
                    name: "充满自停"
                },
            ],
            parametersTableInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersTableList: [
                {
                    title: "产品名称",
                    type: "text",
                    infoOne: [
                        { info: "10路智能充电桩" }
                    ],
                    infoTwo: [
                        { info: "12路智能充电桩" }
                    ],
                },
                {
                    title: "产品型号",
                    type: "text",
                    infoOne: [
                        { info: "C10" }
                    ],
                    infoTwo: [
                        { info: "C10" }
                    ],
                },
                {
                    title: "插座数量",
                    type: "text",
                    infoOne: [
                        { info: "10" }
                    ],
                    infoTwo: [
                        { info: "12" }
                    ],
                },
                {
                    title: "插座数量",
                    type: "text",
                    infoOne: [
                        { info: "10" }
                    ],
                    infoTwo: [
                        { info: "12" }
                    ],
                },
                {
                    title: "产品尺寸",
                    type: "text",
                    infoOne: [
                        { info: "330mm*210mm*85mm" }
                    ],
                    infoTwo: [
                        { info: "330mm*210mm*85mm" }
                    ],
                },
                {
                    title: "产品颜色",
                    type: "text",
                    infoOne: [
                        { info: "蓝色、绿色、橙色" }
                    ],
                    infoTwo: [
                        { info: "蓝色、绿色、橙色" }
                    ],
                },
                {
                    title: "语音播报",
                    type: "text",
                    infoOne: [
                        { info: "支持" }
                    ],
                    infoTwo: [
                        { info: "支持" }
                    ],
                },
                {
                    title: "计费方式",
                    type: "text",
                    infoOne: [
                        { info: "时长、电量、功率、包月等方式" }
                    ],
                    infoTwo: [
                        { info: "时长、电量、功率、包月等方式" }
                    ],
                },
                {
                    title: "用户使用",
                    type: "text",
                    infoOne: [
                        { info: "扫码充电、刷卡充电、卡片充值" }
                    ],
                    infoTwo: [
                        { info: "扫码充电、刷卡充电、卡片充值" }
                    ],
                },
                {
                    title: "安全防护",
                    type: "text",
                    infoOne: [
                        { info: "过载、过压、过充" }
                    ],
                    infoTwo: [
                        { info: "过载、过压、过充" }
                    ],
                },
                {
                    title: "LED指示灯",
                    type: "text",
                    infoOne: [
                        { info: "双色LED指示灯" }
                    ],
                    infoTwo: [
                        { info: "双色LED指示灯" }
                    ],
                },
                {
                    title: "通信方式",
                    type: "text",
                    infoOne: [
                        { info: "4G/LORA" }
                    ],
                    infoTwo: [
                        { info: "4G/LORA" }
                    ],
                },
                {
                    title: "输出口数",
                    type: "text",
                    infoOne: [
                        { info: "10口输入,独立控制" }
                    ],
                    infoTwo: [
                        { info: "12口输入,独立控制" }
                    ],
                },
                {
                    title: "单路输出最大功率",
                    type: "text",
                    infoOne: [
                        { info: "10*2200w" }
                    ],
                    infoTwo: [
                        { info: "12*2200w" }
                    ],
                },
                {
                    title: "电能计量",
                    type: "text",
                    infoOne: [
                        { info: "每个充电口独立计算" }
                    ],
                    infoTwo: [
                        { info: "每个充电口独立计算" }
                    ],
                },
                {
                    title: "输入电压",
                    type: "text",
                    infoOne: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                    infoTwo: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                },
                {
                    title: "输出电压",
                    type: "text",
                    infoOne: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                    infoTwo: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                },
                {
                    title: "单路最大电流",
                    type: "text",
                    infoOne: [
                        { info: "10A" }
                    ],
                    infoTwo: [
                        { info: "10A" }
                    ],
                },
                {
                    title: "供电要求",
                    type: "text",
                    infoOne: [
                        { info: "漏电保护器、避雷" }
                    ],
                    infoTwo: [
                        { info: "漏电保护器、避雷" }
                    ],
                },
                {
                    title: "适应场地",
                    type: "text",
                    infoOne: [
                        { info: "室内、室外" }
                    ],
                    infoTwo: [
                        { info: "室内、室外" }
                    ],
                },
                {
                    title: "工作温度",
                    type: "text",
                    infoOne: [
                        { info: "-20℃-60℃" }
                    ],
                    infoTwo: [
                        { info: "-20℃-60℃" }
                    ],
                },
                {
                    title: "工作温度",
                    type: "text",
                    infoOne: [
                        { info: "<90%不凝露" }
                    ],
                    infoTwo: [
                        { info: "<90%不凝露" }
                    ],
                },
                {
                    title: "环境要求",
                    type: "text",
                    infoOne: [
                        { info: "不可浸水、保持通风、信号优良" }
                    ],
                    infoTwo: [
                        { info: "不可浸水、保持通风、信号优良" }
                    ],
                },
            ],
            advantageInfo: {
                titleChName: "产品优势",
                titleEnName: "Product Advantages"
            },
            advantageList: [
                {
                    icon: "/device/charge/advantage1.png",
                    title: "使用车群广",
                    info: "设备端口采用10/12*2200w,适用两轮电动车、三轮车、老年代步车、运营三轮车等不同车群。"
                },
                {
                    icon: "/device/charge/advantage2.png",
                    title: "使用环境广",
                    info: "创新采用分离式智能管控，可远程开电。支持室内、室外、地下室等区域安装使用。"
                },
                {
                    icon: "/device/charge/advantage3.png",
                    title: "使用人群广",
                    info: "微信扫码支付无需投币，同时支持刷卡充电，解决老人和无智能手机人群充电问题。"
                },

            ],
            displayBottomInfo: {
                bPic: "/device/charge/pDisplayTenBig.png",
                bTitle: "安装场景包含设备、配件如下",
                bList: ['1、10/12路智能充电桩', '2、明装5孔插座', ' 3、智能烟感、悬挂式干粉灭火器、4G/5G摄像机']
            },
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
            picList: [
                {
                    title: "蓝色",
                    pic: "/device/charge/twelveBlueBig.png",
                    color: 'blue'
                },
                {
                    title: "绿色",
                    pic: "/device/charge/twelveGreenBig.png",
                    color: 'green'
                },
                {
                    title: "橙色",
                    pic: "/device/charge/twelveOrangeBig.png",
                    color: 'orange'
                },
            ],
        },
        ig: {
            title: 'LTE-LORA工业级网关',
            tabList: ['多信道并发'],
            content: '负责链接网络服务器和新能源双路智能充电桩通信，实现双路充电桩和云平台之间的报文通信协议转换。支持多信道，内置三网通讯模块。',
            otherObj: {
                // ms: this.$t('hp.info.eat.otherObj.ms'),
                // type: 1,
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/charge/gatewayIcon1.png",
                    name: "远程控制"
                },
                {
                    icon: "/device/charge/gatewayIcon2.png",
                    name: "信道隔离"
                }, {
                    icon: "/device/charge/gatewayIcon3.png",
                    name: "故障上传"
                }, {
                    icon: "/device/charge/gatewayIcon4.png",
                    name: "自主切网"
                }, {
                    icon: "/device/charge/gatewayIcon5.png",
                    name: "远程升级"
                }, {
                    icon: "/device/charge/gatewayIcon6.png",
                    name: "自组网"
                }, {
                    icon: "/device/charge/gatewayIcon7.png",
                    name: "断电记忆"
                }, {
                    icon: "/device/charge/gatewayIcon8.png",
                    name: "离网运行"
                }
            ],
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "LTE-LORA工业级网关"
                },
                {
                    title: "产品型号",
                    info: "A2"
                }, {
                    title: "产品尺寸",
                    info: "204mm*110mm*30mm"
                }, {
                    title: "产品颜色",
                    info: "黑色"
                },
                {
                    title: "通信方式",
                    info: "4G/LORA"
                },
                {
                    title: "通信信道",
                    info: "多信道并发"
                },

                {
                    title: "运营商",
                    info: "移动、联通、电信"
                }, {
                    title: "最大可接",
                    info: "100个双路智能充电桩"
                }, {
                    title: "电源输入",
                    info: "DC 12V 1A"
                }, {
                    title: "输出电压",
                    info: "AC 220V ±10% 50Hz"
                }, {
                    title: "适应场地",
                    info: "室内、室外"
                }, {
                    title: "工作温度",
                    info: "-10℃~50℃"
                }, {
                    title: "工作湿度",
                    info: "10%-90%RH（无冷凝）"
                }, {
                    title: "环境要求",
                    info: "不可浸水、保持通风、信号优良"
                }, {
                    title: "",
                    info: ""
                },
            ],
        },
        nach: {
            title: '网络门禁主机',
            tabList: ['4G/以太网通讯', '双门管理'],
            content: '采用4G和以太网通讯方式，集成电瓶车停车管理平台、门禁控制器、读头卡为一体的智能门禁管理系统，安装施工便捷。支持超大高速数据源存储，可离网运行。支持外接读头设备，实现IC刷卡、RFID刷卡、远程、蓝牙、人脸等多种开门方式。',
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/door/accessAControlIcon1.png",
                    name: "多种开门方式"
                },
                {
                    icon: "/device/door/accessAControlIcon2.png",
                    name: "离网运行"
                }, {
                    icon: "/device/door/accessAControlIcon3.png",
                    name: "海量存储"
                }, {
                    icon: "/device/door/accessAControlIcon4.png",
                    name: "权限设置"
                }, {
                    icon: "/device/door/accessAControlIcon5.png",
                    name: "实时监控"
                }, {
                    icon: "/device/door/accessAControlIcon6.png",
                    name: "记录查询"
                }, {
                    icon: "/device/door/accessAControlIcon7.png",
                    name: "语音播报"
                }, {
                    icon: "/device/door/accessAControlIcon8.png",
                    name: "在线升级"
                }, {
                    icon: "/device/door/accessAControlIcon9.png",
                    name: "远程控制"
                }, {
                    icon: "/device/door/accessAControlIcon10.png",
                    name: "二次开发"
                }, {
                    icon: "/device/door/accessAControlIcon11.png",
                    name: "双门控制"
                }, {
                    icon: "/device/door/accessAControlIcon12.png",
                    name: "三方设备外接"
                }
            ],
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "网络门禁主机"
                },
                {
                    title: "产品型号",
                    info: "S1"
                },
                {
                    title: "管理门数",
                    info: "智能门或闸机2组"
                },
                {
                    title: "读卡器数",
                    info: "4组"
                },
                {
                    title: "注册卡数",
                    info: "8万（可扩容）"
                },
                {
                    title: "产品尺寸",
                    info: "155mm*115mm*32.5mm"
                },

                {
                    title: "语音播报",
                    info: "支持"
                },

                {
                    title: "开门方式",
                    info: "IC/RFID刷卡、人脸、远程、蓝牙"
                },
                {
                    title: "其他拓展",
                    info: "支持三方设备接入"
                },
                {
                    title: "通信方式",
                    info: "4G/以太网"
                },

                {
                    title: "工作电压",
                    info: "18-24V DC"
                }, {
                    title: "工作电流",
                    info: "500mA"
                }, {
                    title: "输出电压",
                    info: "AC 220 ±10% 50Hz"
                }, {
                    title: "待机功率",
                    info: "＜3W"
                }, {
                    title: "工作温度",
                    info: "-70℃~75℃"
                }, {
                    title: "工作湿度",
                    info: "＜90%不凝露"
                },
                {
                    title: "",
                    info: ""
                },
                {
                    title: "",
                    info: ""
                },

            ],
            interfaceDescriptionInfo: {
                titleChName: "接口说明",
                titleEnName: "Interface Description"
            },
            featuresPicInfo: {
                pic: "/device/door/jiekou.png",
                number: "接口序号",
                name: "接口名称",
                function: "接口功能",
                list: [
                    { number: ['1'], name: "RS485/韦根接口1", function: "闸机1刷卡器接口" },
                    { number: ['2'], name: "RS485/韦根接口2", function: "闸机1刷卡器接口" },
                    { number: ['3'], name: "RS485/韦根接口3", function: "闸机2刷卡器接口" },
                    { number: ['4'], name: "RS485/韦根接口4", function: "闸机2刷卡器接口" },
                    { number: ['5'], name: "语音", function: "连接语音" },
                    { number: ['6'], name: "指示灯", function: "主机状态指示灯" },
                    { number: ['7'], name: "开发调试接口", function: "现场调试专用接口" },
                    { number: ['8'], name: "4G天线接口", function: "连接4G天线" },
                    { number: ['9'], name: "电源输入", function: "24V主机电源输入" },
                    { number: ['10'], name: "电源输出", function: "12V备用电源输出或连接智能门禁控制器" },
                    { number: ['11'], name: "消防接口", function: "消防应急接口" },
                    { number: ['12', '13', '14'], name: "触发信号输入", function: "可接地感、红外对射、闭门反馈等" },
                    { number: ['15'], name: "门闸1", function: "1号闸机控制接口" },
                    { number: ['16'], name: "门闸2", function: "2号闸机控制接口" },
                    { number: ['17'], name: "辅助RS485接口", function: "连接RFID设备" },
                    { number: ['18'], name: "以太网口", function: "连接网线" },
                ]
            },
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
        },
        bac: {
            title: '蓝牙门禁控制器',
            tabList: ['蓝牙连接 ', '尺寸小', '功耗低'],
            content: "该模块工作频率高，抗干扰性强。安装后，可进行手机蓝牙开门。",
            infoObj: {
                title1: "蓝牙连接控制",
                title2: "小程序连接控制",
                list: [
                    { title: "工作原理", name: "控制门禁设备" },
                    { title: "工作对象", name: "通过蓝牙，控制继电器开关" },

                ]
            },
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/door/bluetoothAccessControlIcon1.png",
                    name: "蓝牙控制"
                },
                {
                    icon: "/device/door/bluetoothAccessControlIcon2.png",
                    name: "二次开发"
                }, {
                    icon: "/device/door/bluetoothAccessControlIcon3.png",
                    name: "在线升级"
                }, {
                    icon: "/device/door/bluetoothAccessControlIcon4.png",
                    name: "记录查询"
                }
            ],
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "蓝牙门禁控制器"
                },
                {
                    title: "产品型号",
                    info: "S2"
                },
                {
                    title: "产品尺寸",
                    info: "41mm*41mm*15mm"
                },

                {
                    title: "产品功能",
                    info: "蓝牙开门"
                },
                {
                    title: "通信距离",
                    info: "2-5米"
                },
                {
                    title: "最大电流",
                    info: "2A"
                },
                {
                    title: "最大电压",
                    info: "250V AC / 220V DC"
                },
                {
                    title: "供电电压",
                    info: "9-55V DC"
                },
                {
                    title: "最大功率",
                    info: "62.5VA / 60W"
                },
                {
                    title: "适应场地",
                    info: "室内、室外"
                },
                {
                    title: "工作温度",
                    info: "-40 to 125℃"
                }, {
                    title: "工作湿度",
                    info: "＜90%不凝露"
                },
                {
                    title: "环境要求",
                    info: "不可浸水、保持通风"
                },
                {
                    title: "",
                    info: ""
                },
                {
                    title: "",
                    info: ""
                },
            ],
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
        },
        rfid: {
            title: 'RFID读头',
            tabList: ['保密性强 ', '尺寸小', '功耗低'],
            content: '该模块工作频率高，抗干扰性强。安装后，可进行无感开门。',
            otherObj: {
                ms: "RFID读头需配备RFID标签一起使用，RFID标签需单独购买",
                type: 1,
                list: [{
                    title: 'RFID标签',
                    img: `${imgUrl}/device/door/tag.png`,
                    path: ''
                }]
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/door/RFIDReaderIcon1.png",
                    name: "离网运行"
                },
                {
                    icon: "/device/door/RFIDReaderIcon2.png",
                    name: "多标签读写"
                }, {
                    icon: "/device/door/RFIDReaderIcon3.png",
                    name: "权限设置"
                }, {
                    icon: "/device/door/RFIDReaderIcon4.png",
                    name: "记录查询"
                }
                , {
                    icon: "/device/door/RFIDReaderIcon5.png",
                    name: "二次开发"
                }
                , {
                    icon: "/device/door/RFIDReaderIcon6.png",
                    name: "在线升级"
                }
            ],
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "RFID读头"
                },
                {
                    title: "产品型号",
                    info: "S3"
                },
                {
                    title: "产品尺寸",
                    info: "207mm*207mm*40mm"
                },

                {
                    title: "产品功能",
                    info: "感应开门"
                },
                {
                    title: "读写功能",
                    info: "支持多标签识别"
                },
                {
                    title: "识别距离",
                    info: "0-5米"
                },
                {
                    title: "读取速率",
                    info: "＞300次/秒"
                },
                {
                    title: "通信方式",
                    info: "以太网、RS232、RS485/韦根"
                },
                {
                    title: "整机功耗",
                    info: "＜3W"
                },
                {
                    title: "工作电压",
                    info: "12-24V DC"
                },
                {
                    title: "工作电流",
                    info: "500mA"
                },
                {
                    title: "工作湿度",
                    info: "10%RH-90%RH"
                }, {
                    title: "工作温度",
                    info: "-10℃~75℃"
                },
                {
                    title: "",
                    info: ""
                },
                {
                    title: "",
                    info: ""
                },


            ],
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
        },
        sgc: {
            title: 'SGC.智能门禁控制器',
            tabList: ['4G/以太网通讯', '单门控制器'],
            content: '采用4G和以太网通讯方式，集成电瓶车停车管理平台、门禁控制器、读头卡为一体的智能门禁管理系统，安装施工便捷。支持超大高速数据源存储，可离网运行。支持外接读头设备，实现IC刷卡、RFID刷卡、远程、蓝牙、人脸等多种开门方式。',
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: "产品功能",
                titleEnName: "Product function"
            },
            functionList: [
                {
                    icon: "/device/door/accessAControlIcon1.png",
                    name: "多种开门方式"
                },
                {
                    icon: "/device/door/accessAControlIcon2.png",
                    name: "离网运行"
                }, {
                    icon: "/device/door/accessAControlIcon3.png",
                    name: "海量存储"
                }, {
                    icon: "/device/door/accessAControlIcon4.png",
                    name: "权限设置"
                }, {
                    icon: "/device/door/accessAControlIcon5.png",
                    name: "实时监控"
                }, {
                    icon: "/device/door/accessAControlIcon6.png",
                    name: "记录查询"
                }, {
                    icon: "/device/door/accessAControlIcon7.png",
                    name: "语音播报"
                }, {
                    icon: "/device/door/accessAControlIcon8.png",
                    name: "在线升级"
                }, {
                    icon: "/device/door/accessAControlIcon9.png",
                    name: "远程控制"
                }, {
                    icon: "/device/door/accessAControlIcon10.png",
                    name: "二次开发"
                },  {
                    icon: "/device/door/accessAControlIcon12.png",
                    name: "三方设备外接"
                }
            ],
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "SGC.智能门禁控制器"
                },
                {
                    title: "产品型号",
                    info: "S4"
                },
                {
                    title: "管理门数",
                    info: "智能门或闸机1组"
                },
                {
                    title: "读卡器数",
                    info: "2组"
                },
                {
                    title: "注册卡数",
                    info: "8万（可扩容）"
                },
                {
                    title: "产品尺寸",
                    info: "115mm*90mm*40mm"
                },

                {
                    title: "语音播报",
                    info: "支持"
                },

                {
                    title: "开门方式",
                    info: "IC/RFID刷卡、人脸、远程、蓝牙"
                },
                {
                    title: "其他拓展",
                    info: "支持三方设备接入"
                },
                {
                    title: "通信方式",
                    info: "4G/以太网"
                },

                {
                    title: "工作电压",
                    info: "18-24V DC"
                }, {
                    title: "工作电流",
                    info: "500mA"
                }, {
                    title: "输出电压",
                    info: "AC 220 ±10% 50Hz"
                }, {
                    title: "待机功率",
                    info: "＜3W"
                }, {
                    title: "工作温度",
                    info: "-70℃~75℃"
                }, {
                    title: "工作湿度",
                    info: "＜90%不凝露"
                },
                {
                    title: "",
                    info: ""
                },
                {
                    title: "",
                    info: ""
                },
            ],
            scenariosInfo: {
                titleChName: "适用场景",
                titleEnName: "Applicable scenarios"
            },
        },
        sfp: {
            title: '7KW单相交流充电桩(运营版)',
            content: [
                '具备扫码启动与远程停启，配可充值IC卡。',
                '具有充满自停功能。',
                '历史记录功能可以查询历史充电记录和费用记录。',
                '具备过载保护、过压保护、欠压保护、短路保护、过温保护、急停等功能。',
                '支持急停按钮。',
            ],
            otherObj: {
                type: 1,
            },
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },

            functionInfo: {
                titleChName: '产品功能',
                titleEnName: 'Product function',
            },
            functionList: [
                {
                    icon: "/device/energy/energyIcon1.png",
                    name: "适配主流车型"
                },
                {
                    icon: "/device/energy/energyIcon2.png",
                    name: "扫码充电"
                },
                {
                    icon: "/device/energy/energyIcon3.png",
                    name: "远程开电"
                },
                {
                    icon: "/device/energy/energyIcon4.png",
                    name: "急停按钮 "
                },
                {
                    icon: "/device/energy/energyIcon5.png",
                    name: "实时监控"
                },
                {
                    icon: "/device/energy/energyIcon6.png",
                    name: "记录查询"
                },
                {
                    icon: "/device/energy/energyIcon7.png",
                    name: "4G联网 "
                },
                {
                    icon: "/device/energy/energyIcon8.png",
                    name: "在线升级"
                },
            ],

            parametersList: [
                {
                    title: "产品名称",
                    info: "7KW单相交流充电桩"
                }, {
                    title: "额定功率",
                    info: "7KW"
                }, {
                    title: "机身尺寸 (宽*深*高)",
                    info: "190mm*90mm*360mm"
                }, {
                    title: "线缆长度",
                    info: "5m"
                }, {
                    title: "启动方式",
                    info: "扫码"
                }, {
                    title: "安装方式",
                    info: "壁挂式/立柱式"
                }, {
                    title: "试用场景 ",
                    info: "地面车场、地下车场、酒店、景区等"
                }, {
                    title: "输入电压",
                    info: "220Vac"
                }, {
                    title: "输出电压",
                    info: "220Vac"
                }, {
                    title: "输出电流",
                    info: "32A"
                }, {
                    title: "最小/大电流",
                    info: "1/32A"
                }, {
                    title: "工作温度",
                    info: "-30'C~+50'C"
                }, {
                    title: "工作湿度",
                    info: "5%~95%无凝露"
                }, {
                    title: "防护等级",
                    info: "IP55"
                }, {
                    title: "冷却方式",
                    info: "自然冷却 "
                }, {
                    title: "计量等级",
                    info: "2"
                }, {
                    title: "联网方式",
                    info: "4G"
                }, {
                    title: "输出保护",
                    info: "短路保护、漏电保护、过温保护、过流保护"
                }, {
                    title: "防护设计",
                    info: "IP55防护等级、防霉变、防紫外线、防盐实"
                },
            ],
            otherInfo: {
                title: "多种保护",
                info: "欠压保护、过流保护、漏电保护、断路保护、过充保护、过压保护、过温保护、防雷保护、过载保护"
            },
            overviewInfo: {
                titleChName: "产品概述",
                titleEnName: "Product Overview"
            },
            picList: [
                {
                    title: "黑色",
                    pic: "/device/energy/blackBig.png",
                    color: 'orange'
                },
                {
                    title: "白色",
                    pic: "/device/energy/whiteBig.png",
                    color: 'orange'
                },
                {
                    title: "灰色",
                    pic: "/device/energy/grayBig.png",
                    color: 'orange'
                },
            ],
        },
        ssfp: {
            title: '7KW单相交流充电桩(刷卡版)',
            content: [
                '刷卡启动',
                '具有充满自停功能。',
                '具备过载保护、过压保护、欠压保护、短路保护、过温保护、急停等功能。',
                '支持急停按钮。',
            ],
            otherObj: {
                type: 1,
            },
            parametersListInfo: {
                titleChName: "产品参数",
                titleEnName: "Product parameters"
            },

            functionInfo: {
                titleChName: '产品功能',
                titleEnName: 'Product function',
            },
            functionList: [
                {
                    icon: "/device/energy/energyIcon1.png",
                    name: "适配主流车型"
                },
                {
                    icon: "/device/energy/energyIcon2.png",
                    name: "刷卡开电"
                },
                {
                    icon: "/device/energy/energyIcon4.png",
                    name: "急停按钮 "
                },
                {
                    icon: "/device/energy/energyIcon8.png",
                    name: "在线升级"
                },
            ],

            parametersList: [
                {
                    title: "产品名称",
                    info: "7KW单相交流充电桩(刷卡版)"
                }, {
                    title: "额定功率",
                    info: "7KW"
                }, {
                    title: "机身尺寸 (宽*深*高)",
                    info: "190mm*90mm*360mm"
                }, {
                    title: "线缆长度",
                    info: "5m"
                }, {
                    title: "启动方式",
                    info: "刷卡开电"
                }, {
                    title: "安装方式",
                    info: "壁挂式/立柱式"
                }, {
                    title: "试用场景 ",
                    info: "地面车场、地下车场、酒店、景区等"
                }, {
                    title: "输入电压",
                    info: "220Vac"
                }, {
                    title: "输出电压",
                    info: "220Vac"
                }, {
                    title: "输出电流",
                    info: "32A"
                }, {
                    title: "最小/大电流",
                    info: "1/32A"
                }, {
                    title: "工作温度",
                    info: "-30'C~+50'C"
                }, {
                    title: "工作湿度",
                    info: "5%~95%无凝露"
                }, {
                    title: "防护等级",
                    info: "IP55"
                }, {
                    title: "冷却方式",
                    info: "自然冷却 "
                }, {
                    title: "计量等级",
                    info: "2"
                }, {
                    title: "联网方式",
                    info: "4G"
                }, {
                    title: "输出保护",
                    info: "短路保护、漏电保护、过温保护、过流保护"
                }, {
                    title: "防护设计",
                    info: "IP55防护等级、防霉变、防紫外线、防盐实"
                },
            ],
            otherInfo: {
                title: "多种保护",
                info: "欠压保护、过流保护、漏电保护、断路保护、过充保护、过压保护、过温保护、防雷保护、过载保护"
            },
            overviewInfo: {
                titleChName: "产品概述",
                titleEnName: "Product Overview"
            },
            picList: [
                {
                    title: "黑色",
                    pic: "/device/energy/blackBig.png",
                    color: 'orange'
                },
                {
                    title: "白色",
                    pic: "/device/energy/whiteBig.png",
                    color: 'orange'
                },
                {
                    title: "灰色",
                    pic: "/device/energy/grayBig.png",
                    color: 'orange'
                },
            ],
        },
        fpm: {
            title: '4G支付模块',
            tabList: ['便捷扫码', '共享支付'],
            content: [
                '资金安全有保障，结算渠道正规',
                '扫码支付定时通断电源',
                'PC管理端系统+管理端微信小程序+用户支付端微信小程序',
                '办理卡片，规则多样灵活',
                '收费方案自定义',
                '强大后台，功能齐全',
            ],
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: '产品功能',
                titleEnName: 'Product function',
            },
            functionList: [
                {
                    icon: "/device/accessories/paymentModuleIcon1.png",
                    name: "扫码支付"
                },
                {
                    icon: "/device/accessories/paymentModuleIcon2.png",
                    name: "退费功能"
                },
                {
                    icon: "/device/accessories/paymentModuleIcon3.png",
                    name: "费用配置"
                },
            ],
            parametersListInfo: {
                titleChName: '产品参数',
                titleEnName: 'Product parameters'
            },
            parametersList: [
                {
                    title: "产品名称",
                    info: "4G支付模块"
                },
                {
                    title: "产品尺寸",
                    info: "30mm*50mm"
                }, {
                    title: "支付方式",
                    info: "微信"
                }, {
                    title: "工作温度",
                    info: "-40℃~85℃"
                }, {
                    title: "工作电压",
                    info: "3.3-4.2V 推荐值3.7V"
                }, {
                    title: "工作电流",
                    info: "关机＜30uA 待机＜7mA 最大平均600mA "
                }, {
                    title: "使用场景",
                    info: "充电桩、娃娃机、按摩椅等"
                },
            ],
        },
        public: {
            header: {
                text_1: "收起参数",
                text_2: '展开参数'
            },
            scenarioslist: [
                {
                    pic: "/device/public/pScenarios1.png",
                    word: "住宅小区"
                },
                {
                    pic: "/device/public/pScenarios2.png",
                    word: "工业园区"
                },
                {
                    pic: "/device/public/pScenarios3.png",
                    word: "各类学校"
                },
                {
                    pic: "/device/public/pScenarios4.png",
                    word: "办公写字楼"
                },
            ],
            headerPicChTitle: "硬件产品",
            headerPicEnTitle: "Hardware Products",
            headerPicWord: "安全、智能、经济、便捷、稳定",
            classIndex: 0,
            classList: [
                { name: "非机动车充电产品", id: 0 },
                { name: "非机动车停车产品", id: 1 },
                { name: "新能源汽车充电产品", id: 2 },
                // { name: "配件产品", id: 3 },

            ],
            productList: [],
            // 充电桩
            productChargeList: [
                {
                    pic: "/device/public/productCharge1.png",
                    name: "双路智能充电桩",
                    url: "/hardwareProducts/classOne/ordinaryCharge"
                },
                {
                    pic: "/device/public/productCharge2.png",
                    name: "双路智能充电桩",
                    url: "/hardwareProducts/classOne/newCharge"
                }, {
                    pic: "/device/public/productCharge3.png",
                    name: "8+2路智能充电桩",
                    url: "/hardwareProducts/classOne/tenCharge"
                }, {
                    pic: "/device/public/productCharge4.png",
                    name: "LTE-LORA工业级网关",
                    url: "/hardwareProducts/classOne/gateway"
                }, {
                    pic: "/device/public/productCharge5.png",
                    name: "10/12路智能充电桩",
                    url: "/hardwareProducts/classOne/twelveCharge"
                }
            ],
            productIntroductionInfo: {},
            productIntroductionChargeInfo: {
                titleChName: "产品简介",
                titleEnName: "Product Introduction",
                titleWord: "电瓶车充电桩，是一种主要应用于两轮电瓶车、三轮电动车、老年代步车等车辆安全集中的充电设备。产品体积小不占空间，安装施工便捷，智能化程度较高，无需专人值守"
            },
            featuresInfo: {
                titleChName: "产品特点",
                titleEnName: "Product Features"
            },
            featuresList: [],
            featuresChargeList: [
                {
                    pic: "/device/public/feature1.png",
                    name: "安全便捷",
                    infoList: [
                        {
                            name: "具有充满自停、短路、漏电等情况的防护功能"
                        },
                        {
                            name: "灵活支付，可扫码/刷卡支付，全程语音提示"
                        },
                        {
                            name: "对接智能管控平台，可实施查看各种信息"
                        },
                    ]
                },
                {
                    pic: "/device/public/feature2.png",
                    name: "支持OEM定制",
                    infoList: [
                        {
                            name: "支持更改产品丝印外观，拓展功能硬件"
                        },
                        {
                            name: "支持各项功能及配件的选配组合生产"
                        },
                        {
                            name: "支持云平台定制化开发"
                        },
                    ]
                }, {
                    pic: "/device/public/feature3.png",
                    name: "维护简单", infoList: [
                        {
                            name: "采用模块化设计，维护管理便捷"
                        },
                        {
                            name: "支持远程升级"
                        },
                        {
                            name: "自主研发，提供专业维护及技术支持"
                        },
                    ]
                }, {
                    pic: "/device/public/feature4.png",
                    name: "经济收益",
                    infoList: [
                        {
                            name: "小额投资，长期稳定收益"
                        },
                        {
                            name: "厂家全方位扶持"
                        },

                    ]
                }
            ],
            // 停车
            productParkList: [
                {
                    pic: "/device/public/productPark1.png",
                    name: "网络门禁主机",
                    url: "/hardwareProducts/classTwo/accessControl"

                },
                {
                    pic: "/device/public/productPark2.png",
                    name: "蓝牙门禁控制器",
                    url: "/hardwareProducts/classTwo/bluetoothAccessControl"

                }, {
                    pic: "/device/public/productPark3.png",
                    name: "RFID读头",
                    url: "/hardwareProducts/classTwo/RFIDReader"
                }, {
                    pic: "/device/public/productPark4.png",
                    name: "SGC智能门禁控制器",
                    url: "/hardwareProducts/classTwo/SGCControl"
                }
            ],
            productIntroductionParkInfo: {
                titleChName: "产品简介",
                titleEnName: "Product Introduction",
                titleWord: "停车门禁产品，是应用于门禁管理的设备。产品体积小不占空间，安装施工便捷，无需专人值守。安装后可用户可使用小程序扫码、刷卡、蓝牙、人脸、无感等方式开门，可快速通行"
            },
            featuresParkList: [
                {
                    pic: "/device/public/feature1.png",
                    name: "安全便捷",
                    infoList: [
                        {
                            name: "具有多种开门方式，支持小程序、刷卡、感应开门"
                        },
                        {
                            name: "支持临停、包月停车收费设置"
                        },
                        {
                            name: "对接智能管控平台，可实施查看各种信息"
                        },
                    ]
                },
                {
                    pic: "/device/public/feature2.png",
                    name: "支持OEM定制",
                    infoList: [
                        {
                            name: "支持更改产品丝印外观，拓展功能硬件"
                        },
                        {
                            name: "支持各项功能及配件的选配组合生产"
                        },
                        {
                            name: "支持云平台定制化开发"
                        },
                    ]
                }, {
                    pic: "/device/public/feature3.png",
                    name: "维护简单", infoList: [
                        {
                            name: "采用模块化设计，维护管理便捷"
                        },
                        {
                            name: "支持远程升级"
                        },
                        {
                            name: "自主研发，提供专业维护及技术支持"
                        },
                    ]
                }, {
                    pic: "/device/public/feature4.png",
                    name: "经济收益",
                    infoList: [
                        {
                            name: "小额投资，长期稳定收益"
                        },
                        {
                            name: "厂家全方位扶持"
                        },

                    ]
                }
            ],
            // 新能源
            productEnergyList: [
                {
                    pic: "/device/public/productEnergy1.png",
                    name: "7.0KW单相交流桩(运营版)",
                    url: "/hardwareProducts/classThree/Energy"

                },
                {
                    pic: "/device/public/productEnergy2.png",
                    name: "7.0KW单相交流桩(刷卡版)",
                    url: "/hardwareProducts/classThree/SwipeCardEnergy"

                },
            ],
            productIntroductionEnergyInfo: {
                titleChName: "产品简介",
                titleEnName: "Product Introduction",
                titleWord: "新能源充电桩，是一种主要应用于新能源车辆安全集中充电的设备。安装施工便捷，智能化程度较高，无需专人值守"
            },
            featuresEnergyList: [
                {
                    pic: "/device/public/feature1.png",
                    name: "安全便捷",
                    infoList: [
                        {
                            name: "具有充满自停、短路、漏电等情况的防护功能"
                        },
                        {
                            name: "灵活支付，可扫码/刷卡支付，全程语音提示"
                        },
                        {
                            name: "对接智能管控平台，可实施查看各种信息"
                        },
                    ]
                },
                {
                    pic: "/device/public/feature2.png",
                    name: "支持OEM定制",
                    infoList: [
                        {
                            name: "支持更改产品丝印外观，拓展功能硬件"
                        },
                        {
                            name: "支持各项功能及配件的选配组合生产"
                        },
                        {
                            name: "支持云平台定制化开发"
                        },
                    ]
                }, {
                    pic: "/device/public/feature3.png",
                    name: "维护简单", infoList: [
                        {
                            name: "采用模块化设计，维护管理便捷"
                        },
                        {
                            name: "支持远程升级"
                        },
                        {
                            name: "自主研发，提供专业维护及技术支持"
                        },
                    ]
                }, {
                    pic: "/device/public/feature4.png",
                    name: "经济收益",
                    infoList: [
                        {
                            name: "小额投资，长期稳定收益"
                        },
                        {
                            name: "厂家全方位扶持"
                        },

                    ]
                }
            ],
            // 公共
            scenariosInfo: {
                titleChName: "适用范围",
                titleEnName: "Product Advantages"
            },
            purchaseInfo: {
                titleChName: "购货无忧 售后保障",
                titleEnName: "Worry-free after-sales guarantee for purchase"
            },
            purchaseList: [
                {
                    pic: "/device/public/purchase1.png",
                    name: "全程跟踪指导安装及后续服务事项"
                },
                {
                    pic: "/device/public/purchase1.png",
                    name: "软硬件系统免费升级"
                }, {
                    pic: "/device/public/purchase1.png",
                    name: "为OEM客户提供全套文案资料"
                }, {
                    pic: "/device/public/purchase1.png",
                    name: "企业微信群一对一客服跟踪服务"
                }, {
                    pic: "/device/public/purchase1.png",
                    name: "协助场地运营"
                },
            ],
            display: {
                picList: [
                    {
                        title: "蓝色",
                        pic: "/device/charge/blueBig.png",
                        color: 'blue'
                    },
                    {
                        title: "白色",
                        pic: "/device/charge/writeBig.png",
                        color: '#9F9F9F'
                    },
                    {
                        title: "橙色",
                        pic: "/device/charge/orangeBig.png",
                        color: 'orange'
                    },
                ],
                pDisplayInfo: {
                    titleChName: "产品展示",
                    titleEnName: "Product display"
                },
                title: "场景安装图"
            }
        }
    },
    en: {
        dco: {
            title: "DualIntelligentChargingStation",
            tabList: ['4G/LORA', 'SwipeCard + ScanCode'],
            content: "Ac output power remote on-off control, charging safety control, electricity charging, charging function in one of the power supply device. It supports charging of different vehicle types such as two-wheeled three-wheeled vehicles, high-power three-wheelers, operation vehicles and four-wheeled mobility scooters. With flexible payment, full of automatic stop, overload protection and other functions, the payment method can be used by swiping card or scanning code.",
            otherObj: {
                ms: 'The communication mode is LORA dual intelligent charging pile, which needs to be used with a charging gateway',
                type: 1,
                list: [{
                    title: 'LTE-LORA industrial gateway',
                    img: `${imgUrl}/device/charge/d-header-other-1.png`,
                    path: '/hardwareProducts/classOne/gateway'
                }]
            },
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能 "
            },
            functionList: [
                {
                    icon: "/device/charge/feature1.png",
                    name: "Card store value"
                },
                {
                    icon: "/device/charge/feature2.png",
                    name: "Charge of electricity"
                }, {
                    icon: "/device/charge/feature3.png",
                    name: "Charging progress"
                }, {
                    icon: "/device/charge/feature4.png",
                    name: "Overload protection"
                }, {
                    icon: "/device/charge/feature5.png",
                    name: "Pay by credit card"
                }, {
                    icon: "/device/charge/feature6.png",
                    name: "Off-grid operation"
                }, {
                    icon: "/device/charge/feature7.png",
                    name: "Voice prompt"
                }, {
                    icon: "/device/charge/feature8.png",
                    name: "Monthly charge"
                }, {
                    icon: "/device/charge/feature9.png",
                    name: "Wechat Pay"
                }, {
                    icon: "/device/charge/feature10.png",
                    name: "Anomaly detection"
                }, {
                    icon: "/device/charge/feature11.png",
                    name: "Temporary charge"
                }, {
                    icon: "/device/charge/feature12.png",
                    name: "Parking binding"
                }, {
                    icon: "/device/charge/feature13.png",
                    name: "Remote control"
                }, {
                    icon: "/device/charge/feature14.png",
                    name: "Power off refill"
                }, {
                    icon: "/device/charge/feature15.png",
                    name: "Time charge"
                }, {
                    icon: "/device/charge/feature16.png",
                    name: "Full stop"
                },
            ],
            parametersTableInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersTableList: [
                {
                    title: "Product model",
                    type: "text",
                    infoOne: [
                        { info: "C2" }
                    ],
                    infoTwo: [
                        { info: "C2" }
                    ],
                    infoThree: [
                        { info: "C2" }
                    ]
                },
                {
                    title: "Product specification",
                    type: "text",
                    infoOne: [
                        { info: "Small power（1.0KW-4G）" },
                        { info: "Small power（1.0KW-LORA）" },

                    ],
                    infoTwo: [
                        { info: "High power（2.2KW-4G）" },
                        { info: "High power（2.2KW-LORA）" },
                    ],
                    infoThree: [
                        { info: "Big power（3.5KW-4G）" },
                        { info: "Big power（3.5KW-LORA）" },
                    ]
                },
                {
                    title: "Number of sockets",
                    type: "text",
                    infoOne: [
                        { info: "two" },


                    ],
                    infoTwo: [
                        { info: "two" },

                    ],
                    infoThree: [
                        { info: "two" },
                    ]
                },
                {
                    title: "Product size",
                    type: "text",
                    infoOne: [
                        { info: "238mm*115mm*80.6mm" },

                    ],
                    infoTwo: [
                        { info: "238mm*115mm*80.6mm" },

                    ],
                    infoThree: [
                        { info: "238mm*115mm*80.6mm" },

                    ]
                },
                {
                    title: "Product color",
                    type: "image",
                    infoOne: [
                        { info: "/device/charge/write.png" },
                        { info: "/device/charge/orange.png" },
                        { info: "/device/charge/green.png" },


                    ],
                    infoTwo: [
                        { info: "/device/charge/write.png" },
                        { info: "/device/charge/orange.png" },
                        { info: "/device/charge/green.png" },
                    ],
                    infoThree: [
                        { info: "/device/charge/write.png" },
                        { info: "/device/charge/orange.png" },
                        { info: "/device/charge/green.png" },
                    ]
                },
                {
                    title: "Voice broadcasting",
                    type: "text",
                    infoOne: [
                        { info: "support" },


                    ],
                    infoTwo: [
                        { info: "support" },

                    ],
                    infoThree: [
                        { info: "support" },
                    ]
                },
                {
                    title: "Billing mode",
                    type: "text",
                    infoOne: [
                        { info: "Time,electricity,power,monthly payment method" },


                    ],
                    infoTwo: [
                        { info: "Time,electricity,power,monthly payment method" },

                    ],
                    infoThree: [
                        { info: "Time,electricity,power,monthly payment method" },
                    ]
                },
                {
                    title: "User use",
                    type: "text",
                    infoOne: [
                        { info: "Charging by scanning code, charging by swiping card and charging by card" },


                    ],
                    infoTwo: [
                        { info: "Charging by scanning code, charging by swiping card and charging by card" },

                    ],
                    infoThree: [
                        { info: "Charging by scanning code, charging by swiping card and charging by card" },
                    ]
                },
                {
                    title: "Safety protection",
                    type: "text",
                    infoOne: [
                        { info: "Overload, overvoltage, overcharge" },
                    ],
                    infoTwo: [
                        { info: "Overload, overvoltage, overcharge" },

                    ],
                    infoThree: [
                        { info: "Overload, overvoltage, overcharge" },
                    ]
                },
                {
                    title: "LEDindicator",
                    type: "text",
                    infoOne: [
                        { info: "Two-color LED lights" },
                    ],
                    infoTwo: [
                        { info: "Two-color LED lights" },

                    ],
                    infoThree: [
                        { info: "Two-color LED lights" },
                    ]
                },
                {
                    title: "Communication mode",
                    type: "text",
                    infoOne: [
                        { info: "4G/LORA" },
                    ],
                    infoTwo: [
                        { info: "4G/LORA" },

                    ],
                    infoThree: [
                        { info: "4G/LORA" },
                    ]
                },
                {
                    title: "Export number",
                    type: "text",
                    infoOne: [
                        { info: "Double port output, independent control" },
                    ],
                    infoTwo: [
                        { info: "Double port output, independent control" },

                    ],
                    infoThree: [
                        { info: "Double port output, independent control" },
                    ]
                },
                {
                    title: "Maximum output power",
                    type: "text",
                    infoOne: [
                        { info: "1000W" },
                    ],
                    infoTwo: [
                        { info: "2200W" },

                    ],
                    infoThree: [
                        { info: "3500W" },
                    ]
                },
                {
                    title: "Electric energy measurement",
                    type: "text",
                    infoOne: [
                        { info: "Each charging port is independently measured" },
                    ],
                    infoTwo: [
                        { info: "Each charging port is independently measured" },

                    ],
                    infoThree: [
                        { info: "Each charging port is independently measured" },
                    ]
                },
                {
                    title: "Input voltage",
                    type: "text",
                    infoOne: [
                        { info: "AC 220 ±10% 50Hz" },
                    ],
                    infoTwo: [
                        { info: "AC 220 ±10% 50Hz" },

                    ],
                    infoThree: [
                        { info: "AC 220 ±10% 50Hz" },
                    ]
                },
                {
                    title: "Output voltage",
                    type: "text",
                    infoOne: [
                        { info: "AC 220 ±10% 50Hz" },
                    ],
                    infoTwo: [
                        { info: "AC 220 ±10% 50Hz" },

                    ],
                    infoThree: [
                        { info: "AC 220 ±10% 50Hz" },
                    ]
                },
                {
                    title: "Single channel maximum current",
                    type: "text",
                    infoOne: [
                        { info: "6A" },
                    ],
                    infoTwo: [
                        { info: "10A" },

                    ],
                    infoThree: [
                        { info: "16A" },
                    ]
                },
                {
                    title: "Typical power",
                    type: "text",
                    infoOne: [
                        { info: "＜3W" },
                    ],
                    infoTwo: [
                        { info: "＜3W" },

                    ],
                    infoThree: [
                        { info: "＜3W" },
                    ]
                },
                {
                    title: "Power supply requirement",
                    type: "text",
                    infoOne: [
                        { info: "Leakage protector, lightning protection" },
                    ],
                    infoTwo: [
                        { info: "Leakage protector, lightning protection" },

                    ],
                    infoThree: [
                        { info: "Leakage protector, lightning protection" },
                    ]
                },
                {
                    title: "Adaptive site",
                    type: "text",
                    infoOne: [
                        { info: "Indoor and outdoor" },
                    ],
                    infoTwo: [
                        { info: "Indoor and outdoor" },

                    ],
                    infoThree: [
                        { info: "Indoor and outdoor" },
                    ]
                },
                {
                    title: "Operating temperature",
                    type: "text",
                    infoOne: [
                        { info: "-20℃~60℃" },
                    ],
                    infoTwo: [
                        { info: "-20℃~60℃" },

                    ],
                    infoThree: [
                        { info: "-20℃~60℃" },
                    ]
                },
                {
                    title: "Working humidity",
                    type: "text",
                    infoOne: [
                        { info: "＜90%on-condensation" },
                    ],
                    infoTwo: [
                        { info: "＜90%non-condensation" },

                    ],
                    infoThree: [
                        { info: "＜90%non-condensation" },
                    ]
                },
                {
                    title: "Environmental requirements",
                    type: "text",
                    infoOne: [
                        { info: "Non-waterlogged, maintain ventilation, good signal" },
                    ],
                    infoTwo: [
                        { info: "Non-waterlogged, maintain ventilation, good signal" },

                    ],
                    infoThree: [
                        { info: "Non-waterlogged, maintain ventilation, good signal" },
                    ]
                },

            ],
            advantageInfo: {
                titleChName: "Product Advantages",
                titleEnName: "产品优势"
            },
            advantageList: [
                {
                    icon: "/device/charge/advantage1.png",
                    title: "The use of vehicles is wide",
                    info: "It supports charging of different models such as two-wheel electric vehicles, high-power three-wheelers, operating vehicles and four-wheel scooters."
                },
                {
                    icon: "/device/charge/advantage2.png",
                    title: "Wide use environment",
                    info: "Innovative use of separate intelligent control, remote power on, basement and other areas without signal to be covered can be easily handled."
                },
                {
                    icon: "/device/charge/advantage3.png",
                    title: "Wide range of users",
                    info: "Wechat scanning code payment does not require coin insertion, and supports credit card charging, solving the charging problem of the elderly and people without smart phones."
                },

            ],
            displayBottomInfo: {
                bPic: "/device/charge/pDisplayBig.png",
                bTitle: "The installation scenario includes equipment and accessories as follows",
                bList: [' 1、Dual-channel intelligent charging pile', '2、LTE-LORA Industrial grade gateway', ' 3、Intelligent smoke sensing, hanging dry powder fire extinguisher, 4G/5G camera']
            },
            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },
        },
        eat: {
            title: '8+2IntelligentChargingStations',
            tabList: ['4G/LORA', '2200W*Route 8+3500W*Route 2', 'Swipe card + scan code'],
            content: "Ac output power remote on-off control, charging safety control, electricity charging, charging function in one of the power supply device. Support ordinary two-wheeled vehicles, tricycles, elderly mobility scooters, operation tricycles charging. Single channel output power up to 3500W. With flexible payment, full of automatic stop, overload protection and other functions, the payment method can be used by swiping card or scanning code.",
            otherObj: {
                ms: 'Matching sockets need to be purchased separately',
                type: 1,
                list: [
                    {
                        title: 'Concealed socket',
                        img: `${imgUrl}/device/charge/Concealedsocket.png`,
                        path: ''
                    },
                    {
                        title: 'Open socket',
                        img: `${imgUrl}/device/charge/Opensocket.png`,
                        path: '/hardwareProducts/classFour/openSocket'
                    }
                ]
            },
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能"
            },
            functionList: [
                {
                    icon: "/device/charge/feature1.png",
                    name: "Card store value"
                },
                {
                    icon: "/device/charge/feature2.png",
                    name: "Charge of electricity"
                }, {
                    icon: "/device/charge/feature3.png",
                    name: "Charging progress"
                }, {
                    icon: "/device/charge/feature4.png",
                    name: "Overload protection"
                }, {
                    icon: "/device/charge/feature5.png",
                    name: "Pay by credit card"
                }, {
                    icon: "/device/charge/feature17.png",
                    name: "Message reminder"
                }, {
                    icon: "/device/charge/feature7.png",
                    name: "Voice prompt"
                }, {
                    icon: "/device/charge/feature8.png",
                    name: "Monthly charge"
                }, {
                    icon: "/device/charge/feature9.png",
                    name: "Wechat Pay"
                }, {
                    icon: "/device/charge/feature10.png",
                    name: "Anomaly detection"
                }, {
                    icon: "/device/charge/feature11.png",
                    name: "Temporary charge"
                }, {
                    icon: "/device/charge/feature12.png",
                    name: "Parking binding"
                }, {
                    icon: "/device/charge/feature13.png",
                    name: "Remote control"
                }, {
                    icon: "/device/charge/feature14.png",
                    name: "Power off refill"
                }, {
                    icon: "/device/charge/feature15.png",
                    name: "Time charge"
                }, {
                    icon: "/device/charge/feature16.png",
                    name: "Full stop"
                },
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "8+2 way charging pile"
                },
                {
                    title: "Product model",
                    info: "C10"
                }, {
                    title: "Number of sockets",
                    info: "10个"
                }, {
                    title: "Product size",
                    info: "330mm*210mm*85mm"
                }, {
                    title: "Product color",
                    info: "blue"
                }, {
                    title: "Voice broadcasting",
                    info: "support"
                }, {
                    title: "Billing method",
                    info: "Time, electricity, power, monthly subscription and other ways"
                }, {
                    title: "User use",
                    info: "Scanning code charging, card charging, card recharge"
                }, {
                    title: "Safety protection",
                    info: "Overload, overvoltage, overcharge"
                }, {
                    title: "LED indicator light",
                    info: "Dual color LED indicator light"
                },
                {
                    title: "Communication",
                    info: "4G/LORA"
                },
                {
                    title: "Number of output ports",
                    info: "10 port output, independent control"
                },

                {
                    title: "Maximum output power",
                    info: "14KW(63A),2200W*8路+3500W*2路"
                }, {
                    title: "Energy Measurement",
                    info: "Each charging port is independently metered"
                }, {
                    title: "INPUT VOLTAGE",
                    info: "AC 220V ±10% 50Hz"
                }, {
                    title: "Output voltage",
                    info: "AC 220V ±10% 50Hz"
                },

                {
                    title: "Single circuit maximum current",
                    info: "16A"
                }, {
                    title: "Typical power",
                    info: "＜8W"
                }, {
                    title: "Power supply requirements",
                    info: "Leakage protector and lightning protection"
                }, {
                    title: "Adapt to the venue",
                    info: "Indoor and outdoor"
                }, {
                    title: "Operation temperature",
                    info: "-20℃~60℃"
                }, {
                    title: "Working humidity",
                    info: "＜90%Non condensing"
                }, {
                    title: "Environmental requirements",
                    info: "Do not immerse in water, maintain ventilation, and have good signals"
                }, {
                    title: "",
                    info: ""
                },
            ],

            advantageInfo: {
                titleChName: "Product Advantages",
                titleEnName: "产品优势"
            },
            advantageList: [
                {
                    icon: "/device/charge/advantage1.png",
                    title: "Wide range of vehicles used",
                    info: "Adopting a mixed power scheme, including 8 channels supporting power of 2200W ports and 2 channels supporting power of 3500W ports, to solve the charging problems of different vehicle groups such as two wheeled electric vehicles, tricycles, elderly scooters, and operational tricycles."
                },
                {
                    icon: "/device/charge/advantage2.png",
                    title: "Wide usage environment",
                    info: "Innovative use of separate intelligent control, which can be remotely powered on. Support installation and use in indoor, outdoor, basement, and other areas."
                },
                {
                    icon: "/device/charge/advantage3.png",
                    title: "Wide user base",
                    info: "WeChat scanning payment does not require coins, and supports card swiping charging to solve the charging problem for the elderly and those without smartphones."
                },

            ],
            displayBottomInfo: {
                bPic: "/device/charge/pDisplayTenBig.png",
                bTitle: "The installation scenario includes equipment and accessories as follows",
                bList: ['1、8+2 intelligent charging stations', '2、Surface mounted 5-hole socket', ' 3、Intelligent smoke detector, suspended dry powder fire extinguisher, 4G/5G camera']
            },
            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },
        },
        ticp:{
            title: '10/12 IntelligentChargingStations',
            tabList: ['4G/LORA', 'Swipe card + scan code'],
            content: "Ac output power remote on-off control, charging safety control, electricity charging, charging function in one of the power supply device. Support ordinary two-wheeled vehicles, tricycles, elderly mobility scooters, operation tricycles charging. Single channel output power up to 2200W. With flexible payment, full of automatic stop, overload protection and other functions, the payment method can be used by swiping card or scanning code.",
            otherObj: {
                ms: 'Matching sockets need to be purchased separately',
                type: 1,
                list: [
                    {
                        title: 'Concealed socket',
                        img: `${imgUrl}/device/charge/Concealedsocket.png`,
                        path: ''
                    },
                    {
                        title: 'Open socket',
                        img: `${imgUrl}/device/charge/Opensocket.png`,
                        path: '/hardwareProducts/classFour/openSocket'
                    }
                ]
            },
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能"
            },
            functionList: [
                {
                    icon: "/device/charge/feature1.png",
                    name: "Card store value"
                },
                {
                    icon: "/device/charge/feature2.png",
                    name: "Charge of electricity"
                }, {
                    icon: "/device/charge/feature3.png",
                    name: "Charging progress"
                }, {
                    icon: "/device/charge/feature4.png",
                    name: "Overload protection"
                }, {
                    icon: "/device/charge/feature5.png",
                    name: "Pay by credit card"
                }, {
                    icon: "/device/charge/feature17.png",
                    name: "Message reminder"
                }, {
                    icon: "/device/charge/feature7.png",
                    name: "Voice prompt"
                }, {
                    icon: "/device/charge/feature8.png",
                    name: "Monthly charge"
                }, {
                    icon: "/device/charge/feature9.png",
                    name: "Wechat Pay"
                }, {
                    icon: "/device/charge/feature10.png",
                    name: "Anomaly detection"
                }, {
                    icon: "/device/charge/feature11.png",
                    name: "Temporary charge"
                }, {
                    icon: "/device/charge/feature12.png",
                    name: "Parking binding"
                }, {
                    icon: "/device/charge/feature13.png",
                    name: "Remote control"
                }, {
                    icon: "/device/charge/feature14.png",
                    name: "Power off refill"
                }, {
                    icon: "/device/charge/feature15.png",
                    name: "Time charge"
                }, {
                    icon: "/device/charge/feature16.png",
                    name: "Full stop"
                },
            ],
            parametersTableInfo: {
                titleChName: "Product parameters ",
                titleEnName: "产品参数"
            },
            parametersTableList: [
                {
                    title: "Product name",
                    type: "text",
                    infoOne: [
                        { info: "10 intelligent charging pile" }
                    ],
                    infoTwo: [
                        { info: "12 intelligent charging pile" }
                    ],
                },
                {
                    title: "Product model",
                    type: "text",
                    infoOne: [
                        { info: "C10" }
                    ],
                    infoTwo: [
                        { info: "C10" }
                    ],
                },
                {
                    title: "Number of sockets",
                    type: "text",
                    infoOne: [
                        { info: "10" }
                    ],
                    infoTwo: [
                        { info: "12" }
                    ],
                },
                {
                    title: "Product size",
                    type: "text",
                    infoOne: [
                        { info: "330mm*210mm*85mm" }
                    ],
                    infoTwo: [
                        { info: "330mm*210mm*85mm" }
                    ],
                },
                {
                    title: "Product color",
                    type: "text",
                    infoOne: [
                        { info: "Blue, green, orange" }
                    ],
                    infoTwo: [
                        { info: "Blue, green, orange" }
                    ],
                },
                {
                    title: "Voice broadcast",
                    type: "text",
                    infoOne: [
                        { info: "support" }
                    ],
                    infoTwo: [
                        { info: "support" }
                    ],
                },
                {
                    title: "Billing mode",
                    type: "text",
                    infoOne: [
                        { info: "Time, electricity, power, monthly payment, etc" }
                    ],
                    infoTwo: [
                        { info: "Time, electricity, power, monthly payment, etc" }
                    ],
                },
                {
                    title: "User use",
                    type: "text",
                    infoOne: [
                        { info: "Code charging, card charging, card recharge" }
                    ],
                    infoTwo: [
                        { info: "Code charging, card charging, card recharge" }
                    ],
                },
                {
                    title: "Safety protection",
                    type: "text",
                    infoOne: [
                        { info: "Overload, overvoltage, overcharge" }
                    ],
                    infoTwo: [
                        { info: "Overload, overvoltage, overcharge" }
                    ],
                },
                {
                    title: "LED indicator light",
                    type: "text",
                    infoOne: [
                        { info: "Two-color LED indicator light" }
                    ],
                    infoTwo: [
                        { info: "Two-color LED indicator light" }
                    ],
                },
                {
                    title: "Communication mode",
                    type: "text",
                    infoOne: [
                        { info: "4G/LORA" }
                    ],
                    infoTwo: [
                        { info: "4G/LORA" }
                    ],
                },
                {
                    title: "Output number",
                    type: "text",
                    infoOne: [
                        { info: "10-port input, independent control" }
                    ],
                    infoTwo: [
                        { info: "12 port input, independent control" }
                    ],
                },
                {
                    title: "Maximum power of a single output",
                    type: "text",
                    infoOne: [
                        { info: "10*2200w" }
                    ],
                    infoTwo: [
                        { info: "12*2200w" }
                    ],
                },
                {
                    title: "Electric energy measurement",
                    type: "text",
                    infoOne: [
                        { info: "Each charging port is calculated independently" }
                    ],
                    infoTwo: [
                        { info: "Each charging port is calculated independently" }
                    ],
                },
                {
                    title: "Input voltage",
                    type: "text",
                    infoOne: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                    infoTwo: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                },
                {
                    title: "Output voltage",
                    type: "text",
                    infoOne: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                    infoTwo: [
                        { info: "AC 220V±10% 50Hz" }
                    ],
                },
                {
                    title: "Maximum current of a single channel",
                    type: "text",
                    infoOne: [
                        { info: "10A" }
                    ],
                    infoTwo: [
                        { info: "10A" }
                    ],
                },
                {
                    title: "Power supply requirement",
                    type: "text",
                    infoOne: [
                        { info: "Leakage protector, lightning protection" }
                    ],
                    infoTwo: [
                        { info: "Leakage protector, lightning protection" }
                    ],
                },
                {
                    title: "Adaptive site",
                    type: "text",
                    infoOne: [
                        { info: "Indoor and outdoor" }
                    ],
                    infoTwo: [
                        { info: "Indoor and outdoor" }
                    ],
                },
                {
                    title: "Operating temperature",
                    type: "text",
                    infoOne: [
                        { info: "-20℃-60℃" }
                    ],
                    infoTwo: [
                        { info: "-20℃-60℃" }
                    ],
                },
                {
                    title: "Working humidity",
                    type: "text",
                    infoOne: [
                        { info: "<90%non-condensing" }
                    ],
                    infoTwo: [
                        { info: "<90%non-condensing" }
                    ],
                },
                {
                    title: "Environmental requirements",
                    type: "text",
                    infoOne: [
                        { info: "Do not soak, keep ventilation, good signal" }
                    ],
                    infoTwo: [
                        { info: "Do not soak, keep ventilation, good signal" }
                    ],
                },
            ],
            advantageInfo: {
                titleChName: "Product Advantages",
                titleEnName: "产品优势"
            },
            advantageList: [
                {
                    icon: "/device/charge/advantage1.png",
                    title: "Wide range of vehicles used",
                    info: "The equipment port adopts 10/12*2200w, which is suitable for different vehicle groups such as two-wheel electric vehicles, tricycles, elderly scooters, and operation tricycles."
                },
                {
                    icon: "/device/charge/advantage2.png",
                    title: "Wide usage environment",
                    info: "Innovative use of separate intelligent control, which can be remotely powered on. Support installation and use in indoor, outdoor, basement, and other areas."
                },
                {
                    icon: "/device/charge/advantage3.png",
                    title: "Wide user base",
                    info: "WeChat scanning payment does not require coins, and supports card swiping charging to solve the charging problem for the elderly and those without smartphones."
                },
            ],
            displayBottomInfo: {
                bPic: "/device/charge/pDisplayBig.png",
                bTitle: "The installation scenario includes equipment and accessories as follows",
                bList: [' 1、10/12IntelligentChargingStations intelligent charging pile', '2、LTE-LORA Industrial grade gateway', ' 3、Intelligent smoke sensing, hanging dry powder fire extinguisher, 4G/5G camera']
            },
            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },
            picList: [
                {
                    title: "blue",
                    pic: "/device/charge/twelveBlueBig.png",
                    color: 'blue'
                },
                {
                    title: "green",
                    pic: "/device/charge/twelveGreenBig.png",
                    color: 'green'
                },
                {
                    title: "orange",
                    pic: "/device/charge/twelveOrangeBig.png",
                    color: 'orange'
                },
            ],
        },
        ig: {
            title: 'LTE-LORA industrial gateway',
            tabList: ['Multi-channel concurrency'],
            content: ' Responsible for connecting network servers and communicating with new energy dual channel intelligent charging stations, achieving message communication protocol conversion between dual channel charging stations and cloud platforms. Supports multi-channel, with built-in three network communication module',
            otherObj: {
                // ms: this.$t('hp.info.eat.otherObj.ms'),
                // type: 1,
            },
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能"
            },
            functionList: [
                {
                    icon: "/device/charge/gatewayIcon1.png",
                    name: "Remote control"
                },
                {
                    icon: "/device/charge/gatewayIcon2.png",
                    name: "Channel isolation"
                }, {
                    icon: "/device/charge/gatewayIcon3.png",
                    name: "Fault upload"
                }, {
                    icon: "/device/charge/gatewayIcon4.png",
                    name: "Autonomous network switching"
                }, {
                    icon: "/device/charge/gatewayIcon5.png",
                    name: "Remote Upgrade"
                }, {
                    icon: "/device/charge/gatewayIcon6.png",
                    name: "ad-hoc"
                }, {
                    icon: "/device/charge/gatewayIcon7.png",
                    name: "Power outage memory"
                }, {
                    icon: "/device/charge/gatewayIcon8.png",
                    name: "Off grid operation"
                }
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "Product parameters"
            },
            parametersList: [
                {
                    title: "product name",
                    info: "LTE-LORA industrial gateway"
                },
                {
                    title: "Product model number",
                    info: "A2"
                }, {
                    title: "Dimensions of products",
                    info: "204mm*110mm*30mm"
                }, {
                    title: "Product color",
                    info: "black"
                },
                {
                    title: "Mode of communication",
                    info: "4G/LORA"
                },
                {
                    title: "Communication channel",
                    info: "Multi-channel concurrency"
                },

                {
                    title: "operator",
                    info: "Mobile, Unicom, Telecom"
                }, {
                    title: "Maximum accessible",
                    info: "100 two-way smart charging piles"
                }, {
                    title: "Power input",
                    info: "DC 12V 1A"
                }, {
                    title: "Output voltage",
                    info: "AC 220V ±10% 50Hz"
                }, {
                    title: "Field of adaptation",
                    info: "Indoor and outdoor"
                }, {
                    title: "Operating temperature",
                    info: "-10℃~50℃"
                }, {
                    title: "Humidity at work",
                    info: "10%-90%RH（Condensation free）"
                }, {
                    title: "Environmental requirements",
                    info: "Do not soak, keep ventilation, good signal"
                }
            ],
        },
        nach: {
            title: 'Network access control host',
            tabList: ['4G/ Ethernet communication', 'Two-door management'],
            content: 'Adopt 4G and Ethernet communication mode, integrated battery car parking management platform, access controller, read head card as one of the intelligent access control system, easy installation and construction. Support large high-speed data source storage, can run off the network. Support external reading head devices, IC card, RFID card, remote, Bluetooth, face and other ways to open the door.',
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能 "
            },
            functionList: [
                {
                    icon: "/device/door/accessAControlIcon1.png",
                    name: "Multiple opening modes"
                },
                {
                    icon: "/device/door/accessAControlIcon2.png",
                    name: "Off-grid operation"
                }, {
                    icon: "/device/door/accessAControlIcon3.png",
                    name: "Mass storage"
                }, {
                    icon: "/device/door/accessAControlIcon4.png",
                    name: "Permission setting"
                }, {
                    icon: "/device/door/accessAControlIcon5.png",
                    name: "Real-time monitoring"
                }, {
                    icon: "/device/door/accessAControlIcon6.png",
                    name: "Record query"
                }, {
                    icon: "/device/door/accessAControlIcon7.png",
                    name: "Voice broadcasting"
                }, {
                    icon: "/device/door/accessAControlIcon8.png",
                    name: "Online upgrade"
                }, {
                    icon: "/device/door/accessAControlIcon9.png",
                    name: "Remote control"
                }, {
                    icon: "/device/door/accessAControlIcon10.png",
                    name: "Secondary development"
                }, {
                    icon: "/device/door/accessAControlIcon11.png",
                    name: "Two-door control"
                }, {
                    icon: "/device/door/accessAControlIcon12.png",
                    name: "External connection of three-party devices"
                }
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "Network access control host"
                },
                {
                    title: "Product model",
                    info: "S1"
                },
                {
                    title: "Number of managed doors",
                    info: "Smart door or gate 2 sets"
                },
                {
                    title: "Number of card readers",
                    info: "4组"
                },
                {
                    title: "Number of registered cards",
                    info: "80,000 (expandable)"
                },
                {
                    title: "Product size",
                    info: "155mm*115mm*32.5mm"
                },

                {
                    title: "Voice broadcasting",
                    info: "support"
                },

                {
                    title: "Opening mode",
                    info: "IC/RFID card, face, remote, Bluetooth"
                },
                {
                    title: "Other expansion",
                    info: "Supports three-party device access"
                },
                {
                    title: "Communication mode",
                    info: "4G/ Ethernet"
                },

                {
                    title: "Operating voltage",
                    info: "18-24V DC"
                }, {
                    title: "Working current",
                    info: "500mA"
                }, {
                    title: "Output voltage",
                    info: "AC 220 ±10% 50Hz"
                }, {
                    title: "Standby power",
                    info: "＜3W"
                }, {
                    title: "Operating temperature",
                    info: "-70℃~75℃"
                }, {
                    title: "Working humidity",
                    info: "＜90%Non-condensation"
                },


            ],
            interfaceDescriptionInfo: {
                titleChName: "Interface Description",
                titleEnName: "接口说明"
            },
            featuresPicInfo: {
                pic: "/device/door/jiekou.png",
                number: "Interface number",
                name: "Interface name",
                function: "Interface function",
                list: [
                    { number: ['1'], name: "RS485/ Wigan port 1", function: "Gate 1 card reader interface" },
                    { number: ['2'], name: "RS485/ Wigan port 2", function: "Gate 1 card reader interface" },
                    { number: ['3'], name: "RS485/ Wigan port 3", function: "Gate 2 card reader interface" },
                    { number: ['4'], name: "RS485/ Wigan port 4", function: "Gate 2 card reader interface" },
                    { number: ['5'], name: "voice", function: "Connected speech" },
                    { number: ['6'], name: "Indicator light", function: "Main engine status indicator" },
                    { number: ['7'], name: "Development debugging interface", function: "Special interface for field debugging" },
                    { number: ['8'], name: "4G antenna interface", function: "Connect 4G antenna" },
                    { number: ['9'], name: "Power input", function: "24V host power input" },
                    { number: ['10'], name: "Power output", function: "12V standby power output or connect to the intelligent access controller" },
                    { number: ['11'], name: "Fire interface", function: "Fire emergency interface" },
                    { number: ['12', '13', '14'], name: "Trigger signal input", function: "Ground sensing, infrared reflectivity, closed-door feedback, etc" },
                    { number: ['15'], name: "Gate 1", function: "Gate control interface No.1" },
                    { number: ['16'], name: "Gate 2", function: "Gate control interface No.2" },
                    { number: ['17'], name: "Auxiliary RS485 interface", function: "Connect RFID devices" },
                    { number: ['18'], name: "Ethernet port", function: "Connecting network cable" },
                ]
            },
            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },
        },
        bac: {
            title: 'Bluetooth access controller',
            tabList: ['Bluetooth connection ', 'Small size', 'Low power consumption'],
            content: "The module has high working frequency and strong anti-interference. After installation, you can open the door using Bluetooth.",
            infoObj: {
                title1: "Bluetooth connection control",
                title2: "Small program connection control",
                list: [
                    { title: "Working principle", name: "Control access control equipment" },
                    { title: "Working object", name: "Control the relay switch through Bluetooth" },

                ]
            },
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能"
            },
            functionList: [
                {
                    icon: "/device/door/bluetoothAccessControlIcon1.png",
                    name: "Bluetooth control"
                },
                {
                    icon: "/device/door/bluetoothAccessControlIcon2.png",
                    name: "Secondary development"
                }, {
                    icon: "/device/door/bluetoothAccessControlIcon3.png",
                    name: "Online upgrade"
                }, {
                    icon: "/device/door/bluetoothAccessControlIcon4.png",
                    name: "Record query"
                }
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "Bluetooth access controller"
                },
                {
                    title: "Product model",
                    info: "S2"
                },
                {
                    title: "Product size",
                    info: "41mm*41mm*15mm"
                },

                {
                    title: "Product function",
                    info: "Bluetooth door opening"
                },
                {
                    title: "Communication distance",
                    info: "2-5米"
                },
                {
                    title: "Maximum current",
                    info: "2A"
                },
                {
                    title: "Maximum voltage",
                    info: "250V AC / 220V DC"
                },
                {
                    title: "Supply voltage",
                    info: "9-55V DC"
                },
                {
                    title: "Maximum power",
                    info: "62.5VA / 60W"
                },
                {
                    title: "Adaptive site",
                    info: "室内、室外"
                },
                {
                    title: "Operating temperature",
                    info: "-40 to 125℃"
                }, {
                    title: "Working humidity",
                    info: "＜90%non-condensation"
                },
                {
                    title: "Environmental requirements",
                    info: "Do not immerse in water and keep ventilated"
                },
                {
                    title: "",
                    info: ""
                },
            ],
            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },

        },
        rfid: {
            title: 'RFID reader',
            tabList: ['Strong confidentiality ', 'Small size', 'Low power consumption'],
            content: 'The module has high working frequency and strong anti-interference. After installation, the door can be opened without feeling.',
            otherObj: {
                ms: "RFID reader head should be equipped with RFID tags to use together, RFID tags need to be purchased separately",
                type: 1,
                list: [{
                    title: 'RFID tag',

                    img: `${imgUrl}/device/door/tag.png`,
                    path: ''
                }]
            },
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能"
            },
            functionList: [
                {
                    icon: "/device/door/RFIDReaderIcon1.png",
                    name: "Off-grid operation"
                },
                {
                    icon: "/device/door/RFIDReaderIcon2.png",
                    name: "Multi-label read and write"
                }, {
                    icon: "/device/door/RFIDReaderIcon3.png",
                    name: "Permission setting"
                }, {
                    icon: "/device/door/RFIDReaderIcon4.png",
                    name: "Record query"
                }
                , {
                    icon: "/device/door/RFIDReaderIcon5.png",
                    name: "Secondary development"
                }
                , {
                    icon: "/device/door/RFIDReaderIcon6.png",
                    name: "Online upgrade"
                }
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "RFID reader"
                },
                {
                    title: "Product model",
                    info: "S3"
                },
                {
                    title: "Product size",
                    info: "207mm*207mm*40mm"
                },

                {
                    title: "Product function",
                    info: "Induction door opening"
                },
                {
                    title: "Read and write function",
                    info: "Supports multi-label identification"
                },
                {
                    title: "Identification distance",
                    info: "0-5m"
                },
                {
                    title: "Reading rate",
                    info: "＞300 times per second"
                },
                {
                    title: "Communication mode",
                    info: "Ethernet, RS232, RS485/ Weigen"
                },
                {
                    title: "Overall power consumption",
                    info: "＜3W"
                },
                {
                    title: "Operating voltage",
                    info: "12-24V DC"
                },
                {
                    title: "Working current",
                    info: "500mA"
                },
                {
                    title: "Working humidity",
                    info: "10%RH-90%RH"
                }, {
                    title: "Operating temperature",
                    info: "-10℃~75℃"
                },
                {
                    title: "",
                    info: ""
                },
            ],
            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },
        },
        sgc: {
            title: 'SGC. intelligent access control controller',
            tabList: ['4G/ Ethernet communication', 'One-door management'],
            content: 'Adopt 4G and Ethernet communication mode, integrated battery car parking management platform, access controller, read head card as one of the intelligent access control system, easy installation and construction. Support large high-speed data source storage, can run off the network. Support external reading head devices, IC card, RFID card, remote, Bluetooth, face and other ways to open the door.',
            functionInfo: {
                titleChName: "Product function",
                titleEnName: "产品功能 "
            },
            functionList: [
                {
                    icon: "/device/door/accessAControlIcon1.png",
                    name: "Multiple opening modes"
                },
                {
                    icon: "/device/door/accessAControlIcon2.png",
                    name: "Off-grid operation"
                }, {
                    icon: "/device/door/accessAControlIcon3.png",
                    name: "Mass storage"
                }, {
                    icon: "/device/door/accessAControlIcon4.png",
                    name: "Permission setting"
                }, {
                    icon: "/device/door/accessAControlIcon5.png",
                    name: "Real-time monitoring"
                }, {
                    icon: "/device/door/accessAControlIcon6.png",
                    name: "Record query"
                }, {
                    icon: "/device/door/accessAControlIcon7.png",
                    name: "Voice broadcasting"
                }, {
                    icon: "/device/door/accessAControlIcon8.png",
                    name: "Online upgrade"
                }, {
                    icon: "/device/door/accessAControlIcon9.png",
                    name: "Remote control"
                }, {
                    icon: "/device/door/accessAControlIcon10.png",
                    name: "Secondary development"
                },  {
                    icon: "/device/door/accessAControlIcon12.png",
                    name: "External connection of three-party devices"
                }
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "SGC. intelligent access control controller"
                },
                {
                    title: "Product model",
                    info: "S4"
                },
                {
                    title: "Number of managed doors",
                    info: "Smart door or gate 1 sets"
                },
                {
                    title: "Number of card readers",
                    info: "2"
                },

                {
                    title: "Number of registered cards",
                    info: "80,000 (expandable)"
                },
                {
                    title: "Product size",
                    info: "115mm*90mm*40mm"
                },

                {
                    title: "Voice broadcasting",
                    info: "support"
                },

                {
                    title: "Opening mode",
                    info: "IC/RFID card, face, remote, Bluetooth"
                },
                {
                    title: "Other expansion",
                    info: "Supports three-party device access"
                },
                {
                    title: "Communication mode",
                    info: "4G/ Ethernet"
                },

                {
                    title: "Operating voltage",
                    info: "18-24V DC"
                }, {
                    title: "Working current",
                    info: "500mA"
                }, {
                    title: "Output voltage",
                    info: "AC 220 ±10% 50Hz"
                }, {
                    title: "Standby power",
                    info: "＜3W"
                }, {
                    title: "Operating temperature",
                    info: "-70℃~75℃"
                }, {
                    title: "Working humidity",
                    info: "＜90%Non-condensation"
                },


            ],

            scenariosInfo: {
                titleChName: "Applicable scenarios",
                titleEnName: "适用场景"
            },
        },
        sfp: {
            title: '7KW single-phase AC charging pile (operational version)',
            content: [
                'With scan code start and remote stop start, with rechargeable IC card.',
                'With full stop function.',
                'The history function can query the charging history and charge history.',
                'With overload protection, over voltage protection, under voltage protection, short circuit protection, over temperature protection, emergency stop and other functions.',
                'Support emergency stop button.',

            ],
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: 'Product function',
                titleEnName: '产品功能',
            },
            functionList: [
                {
                    icon: "/device/energy/energyIcon1.png",
                    name: "Suitable for mainstream models"
                },
                {
                    icon: "/device/energy/energyIcon2.png",
                    name: "Scanning code charging"
                },
                {
                    icon: "/device/energy/energyIcon3.png",
                    name: "Remote power-on"
                },
                {
                    icon: "/device/energy/energyIcon4.png",
                    name: "Scram button "
                },
                {
                    icon: "/device/energy/energyIcon5.png",
                    name: "Real-time monitoring"
                },
                {
                    icon: "/device/energy/energyIcon6.png",
                    name: "Record query"
                },
                {
                    icon: "/device/energy/energyIcon7.png",
                    name: "4G networking "
                },
                {
                    icon: "/device/energy/energyIcon8.png",
                    name: "Online upgrade"
                },
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "7KW single-phase AC charging pile"
                }, {
                    title: "Rated power",
                    info: "7KW"
                }, {
                    title: "Body size (W * D * H)",
                    info: "190mm*90mm*360mm"
                }, {
                    title: "Cable length",
                    info: "5m"
                }, {
                    title: "Starting mode",
                    info: "Scan the code"
                }, {
                    title: "Installation mode",
                    info: "Wall mounted/column type"
                }, {
                    title: "Trial scenario ",
                    info: "Ground car park, underground car park, hotel, scenic spot"
                }, {
                    title: "Input voltage",
                    info: "220Vac"
                }, {
                    title: "Output voltage",
                    info: "220Vac"
                }, {
                    title: "Output current",
                    info: "32A"
                }, {
                    title: "Minimum/maximum current",
                    info: "1/32A"
                }, {
                    title: "Operating temperature",
                    info: "-30'C~+50'C"
                }, {
                    title: "Working humidity",
                    info: "5%~95% no condensation"
                }, {
                    title: "Class of protection",
                    info: "IP55"
                }, {
                    title: "Cooling mode",
                    info: "Natural cooling "
                }, {
                    title: "Metrological grade",
                    info: "2"
                }, {
                    title: "Networking mode",
                    info: "4G"
                }, {
                    title: "Output protection",
                    info: "Short circuit protection, leakage protection, over temperature protection, over current protection"
                }, {
                    title: "Protection design",
                    info: "IP55 protection grade, anti-mildew, anti-ultraviolet, anti-salt spray"
                }
            ],
            otherInfo: {
                title: "Multiple protection",
                info: "Under voltage protection, over current protection, leakage protection, open circuit protection, over charge protection, over voltage protection, over temperature protection, lightning protection, over cut protection"
            },
            overviewInfo: {
                titleChName: "Product Overview",
                titleEnName: "产品概述"
            },
            picList: [
                {
                    title: "black",
                    pic: "/device/energy/blackBig.png",
                    color: 'orange'
                },
                {
                    title: "white",
                    pic: "/device/energy/whiteBig.png",
                    color: 'orange'
                },
                {
                    title: "gray",
                    pic: "/device/energy/grayBig.png",
                    color: 'orange'
                },
            ],
        },
        ssfp: {
            title: '7KW single-phase AC charging pile (swipe card version)',
            content: [
                'Credit card startup.',
                'With full stop function.',
                'With overload protection, over voltage protection, under voltage protection, short circuit protection, over temperature protection, emergency stop and other functions.',
                'Support emergency stop button.',

            ],
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: 'Product function',
                titleEnName: '产品功能',
            },
            functionList: [
                {
                    icon: "/device/energy/energyIcon1.png",
                    name: "Suitable for mainstream models"
                },
                {
                    icon: "/device/energy/energyIcon2.png",
                    name: "Swipe the card to turn on the powe"
                },
                {
                    icon: "/device/energy/energyIcon4.png",
                    name: "Scram button "
                },
                {
                    icon: "/device/energy/energyIcon8.png",
                    name: "Online upgrade"
                },
            ],
            parametersListInfo: {
                titleChName: "Product parameters",
                titleEnName: "产品参数"
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "7KW single-phase AC charging pile (swipe card version)"
                }, {
                    title: "Rated power",
                    info: "7KW"
                }, {
                    title: "Body size (W * D * H)",
                    info: "190mm*90mm*360mm"
                }, {
                    title: "Cable length",
                    info: "5m"
                }, {
                    title: "Starting mode",
                    info: "Swipe card to power on"
                }, {
                    title: "Installation mode",
                    info: "Wall mounted/column type"
                }, {
                    title: "Trial scenario ",
                    info: "Ground car park, underground car park, hotel, scenic spot"
                }, {
                    title: "Input voltage",
                    info: "220Vac"
                }, {
                    title: "Output voltage",
                    info: "220Vac"
                }, {
                    title: "Output current",
                    info: "32A"
                }, {
                    title: "Minimum/maximum current",
                    info: "1/32A"
                }, {
                    title: "Operating temperature",
                    info: "-30'C~+50'C"
                }, {
                    title: "Working humidity",
                    info: "5%~95% no condensation"
                }, {
                    title: "Class of protection",
                    info: "IP55"
                }, {
                    title: "Cooling mode",
                    info: "Natural cooling "
                }, {
                    title: "Metrological grade",
                    info: "2"
                }, {
                    title: "Networking mode",
                    info: "4G"
                }, {
                    title: "Output protection",
                    info: "Short circuit protection, leakage protection, over temperature protection, over current protection"
                }, {
                    title: "Protection design",
                    info: "IP55 protection grade, anti-mildew, anti-ultraviolet, anti-salt spray"
                }
            ],
            otherInfo: {
                title: "Multiple protection",
                info: "Under voltage protection, over current protection, leakage protection, open circuit protection, over charge protection, over voltage protection, over temperature protection, lightning protection, over cut protection"
            },
            overviewInfo: {
                titleChName: "Product Overview",
                titleEnName: "产品概述"
            },
            picList: [
                {
                    title: "black",
                    pic: "/device/energy/blackBig.png",
                    color: 'orange'
                },
                {
                    title: "white",
                    pic: "/device/energy/whiteBig.png",
                    color: 'orange'
                },
                {
                    title: "gray",
                    pic: "/device/energy/grayBig.png",
                    color: 'orange'
                },
            ],
        },
        fpm: {
            title: '4G payment module',
            tabList: ['Convenient code scanning', 'Shared payment'],
            content: [
                'Security of funds, settlement channels formal',
                'Scan the code to pay time off the power supply',
                'PC management system + management side wechat mini program + user payment side wechat mini program',
                'For cards, the rules are varied and flexible',
                'The charging scheme is customized',
                'Powerful background, complete functions',
            ],
            otherObj: {
                type: 1,
            },
            functionInfo: {
                titleChName: 'Product function ',
                titleEnName: '产品功能',
            },
            functionList: [
                {
                    icon: "/device/accessories/paymentModuleIcon1.png",
                    name: "scan to pay"
                },
                {
                    icon: "/device/accessories/paymentModuleIcon2.png",
                    name: "Refund function"
                },
                {
                    icon: "/device/accessories/paymentModuleIcon3.png",
                    name: "Cost allocation"
                },
            ],
            parametersListInfo: {
                titleChName: 'Product parameters',
                titleEnName: '产品参数'
            },
            parametersList: [
                {
                    title: "Product name",
                    info: "4G payment module"
                },
                {
                    title: "Product size",
                    info: "30mm*50mm"
                }, {
                    title: "Method of payment",
                    info: "wechat"
                }, {
                    title: "Operating temperature",
                    info: "-40℃~85℃"
                }, {
                    title: "Operating voltage",
                    info: "3.3-4.2V The recommended value is 3.7V"
                }, {
                    title: "Working current",
                    info: "Off < 30uA Standby < 7mA Maximum Average 600mA"
                }, {
                    title: "Usage scenario",
                    info: "Charging pile, doll machine, massage chair"
                }
            ],
        },
        public: {
            header: {
                text_1: "Collapse parameters",
                text_2: 'Expand parameters'
            },
            scenarioslist: [
                {
                    pic: "/device/public/pScenarios1.png",
                    word: "Residential district"
                },
                {
                    pic: "/device/public/pScenarios2.png",
                    word: "Industrial park"
                },
                {
                    pic: "/device/public/pScenarios3.png",
                    word: "Various schools"
                },
                {
                    pic: "/device/public/pScenarios4.png",
                    word: "Office building"
                },
            ],
            headerPicChTitle: "Hardware Products",
            headerPicEnTitle: "硬件产品",
            headerPicWord: "Safe, intelligent, economical, convenient and stable",
            classList: [
                { name: "Non-motor vehicle charging products", id: 0 },
                { name: "Non-motor vehicle parking products", id: 1 },
                { name: "New energy vehicle charging products", id: 2 },
                // { name: "Accessory products", id: 3 },

            ],
            productList: [],
            // 充电桩
            productChargeList: [
                {
                    pic: "/device/public/productCharge1.png",
                    name: "Dual intelligent charging pile",
                    url: "/hardwareProducts/classOne/ordinaryCharge"
                },
                {
                    pic: "/device/public/productCharge2.png",
                    name: "Dual intelligent charging pile",
                    url: "/hardwareProducts/classOne/newCharge"
                }, {
                    pic: "/device/public/productCharge3.png",
                    name: "8+2 intelligent charging pile",
                    url: "/hardwareProducts/classOne/tenCharge"
                }, {
                    pic: "/device/public/productCharge4.png",
                    name: "LTE-LORA industrial gateway",
                    url: "/hardwareProducts/classOne/gateway"
                }, {
                    pic: "/device/public/productCharge5.png",
                    name: "10/12 intelligent charging pile",
                    url: "/hardwareProducts/classOne/twelveCharge"
                }
            ],
            productIntroductionInfo: {},
            productIntroductionChargeInfo: {
                titleChName: "Product Introduction",
                titleEnName: "产品简介",
                titleWord: "Battery car charging pile is a kind of safe and centralized charging equipment products mainly used in two-wheeled battery cars, three-wheeled electric vehicles, mobility scooters for the elderly and other vehicles. It is small in size, does not take up space, convenient in installation and construction, high in intelligence, and does not need special personnel on duty"
            },
            featuresInfo: {
                titleChName: "Product Features",
                titleEnName: "产品特点"
            },
            featuresList: [],
            featuresChargeList: [
                {
                    pic: "/device/public/feature1.png",
                    name: "Safe and convenient",
                    infoList: [
                        {
                            name: "With full stop, short circuit, leakage and other cases of protection"
                        },
                        {
                            name: "Flexible payment, scan code/swipe card payment, the whole process of voice prompt"
                        },
                        {
                            name: "Connected to the intelligent management and control platform, you can view various information"
                        },
                    ]
                },
                {
                    pic: "/device/public/feature2.png",
                    name: "Support OEM customization",
                    infoList: [
                        {
                            name: "Support to change the appearance of the product screen printing, expand the function of the hardware"
                        },
                        {
                            name: "Support the function and accessories of the combination of production"
                        },
                        {
                            name: "Support customized development of cloud platform"
                        },
                    ]
                }, {
                    pic: "/device/public/feature3.png",
                    name: "Simple maintenance",
                    infoList: [
                        {
                            name: "Modular design, convenient maintenance and management"
                        },
                        {
                            name: "Support remote upgrade"
                        },
                        {
                            name: "Independent research and development, provide professional maintenance and technical support"
                        },
                    ]
                }, {
                    pic: "/device/public/feature4.png",
                    name: "Economic income",
                    infoList: [
                        {
                            name: "Small investment, long-term stable returns"
                        },
                        {
                            name: "Full support from the manufacturer"
                        },
                    ]
                }
            ],
            // 停车
            productParkList: [
                {
                    pic: "/device/public/productPark1.png",
                    name: "Network access control host",
                    url: "/hardwareProducts/classTwo/accessControl"
                },
                {
                    pic: "/device/public/productPark2.png",
                    name: "Bluetooth access controller",
                    url: "/hardwareProducts/classTwo/bluetoothAccessControl"

                }, {
                    pic: "/device/public/productPark3.png",
                    name: "RFID reader",
                    url: "/hardwareProducts/classTwo/RFIDReader"
                }, {
                    pic: "/device/public/productPark4.png",
                    name: "SGC intelligent access control controller",
                    url: "/hardwareProducts/classTwo/SGCControl"
                }
            ],
            productIntroductionParkInfo: {
                titleChName: "Product Introduction",
                titleEnName: "产品简介",
                titleWord: "Parking access control products are equipment used in access control management. The product is small, does not take up space, convenient installation and construction, no special person is required to be on duty after installation, users can use small program scan code, swipe card, Bluetooth, face, no sense and other ways to open the door, can be fast"
            },
            featuresParkList: [
                {
                    pic: "/device/public/feature1.png",
                    name: "Safe and convenient",
                    infoList: [
                        {
                            name: "With a variety of ways to open the door, support small program, swipe card, induction door"
                        },
                        {
                            name: "Support temporary parking, monthly parking fee Settings"
                        },
                        {
                            name: "Connected to the intelligent management and control platform, you can view various information"
                        },
                    ]
                },
                {
                    pic: "/device/public/feature2.png",
                    name: "Support OEM customization",
                    infoList: [
                        {
                            name: "Support to change the appearance of the product screen printing, expand the function of the hardware"
                        },
                        {
                            name: "Support the function and accessories of the combination of production"
                        },
                        {
                            name: "Support customized development of cloud platform"
                        },
                    ]
                }, {
                    pic: "/device/public/feature3.png",
                    name: "Simple maintenance",
                    infoList: [
                        {
                            name: "Modular design, convenient maintenance and management"
                        },
                        {
                            name: "Support remote upgrade"
                        },
                        {
                            name: "Independent research and development, provide professional maintenance and technical support"
                        },
                    ]
                }, {
                    pic: "/device/public/feature4.png",
                    name: "Economic income",
                    infoList: [
                        {
                            name: "Small investment, long-term stable returns"
                        },
                        {
                            name: "Full support from the manufacturer"
                        },
                    ]
                }
            ],
            // 新能源
            productEnergyList: [
                {
                    pic: "/device/public/productEnergy1.png",
                    name: "7.0KW single-phase AC pile (operational version)",
                    url: "/hardwareProducts/classThree/Energy"
                },
                {
                    pic: "/device/public/productEnergy2.png",
                    name: "7.0KW single-phase AC pile (swipe card version)",
                    url: "/hardwareProducts/classThree/SwipeCardEnergy"

                },
            ],
            productIntroductionEnergyInfo: {
                titleChName: "Product Introduction",
                titleEnName: "产品简介",
                titleWord: "The new energy charging pile is a kind of equipment mainly used in the safe and centralized charging of new energy vehicles. Convenient installation and construction, high degree of intelligence, no special duty"
            },
            featuresEnergyList: [
                {
                    pic: "/device/public/feature1.png",
                    name: "Safe and convenient",
                    infoList: [
                        {
                            name: "With full stop, short circuit, leakage and other cases of protection"
                        },
                        {
                            name: "Flexible payment, scan code/swipe card payment, the whole process of voice prompt"
                        },
                        {
                            name: "Connected to the intelligent management and control platform, you can view various information"
                        },
                    ]
                },
                {
                    pic: "/device/public/feature2.png",
                    name: "Support OEM customization",
                    infoList: [
                        {
                            name: "Support to change the appearance of the product screen printing, expand the function of the hardware"
                        },
                        {
                            name: "Support the function and accessories of the combination of production"
                        },
                        {
                            name: "Support customized development of cloud platform"
                        },
                    ]
                }, {
                    pic: "/device/public/feature3.png",
                    name: "Simple maintenance",
                    infoList: [
                        {
                            name: "Modular design, convenient maintenance and management"
                        },
                        {
                            name: "Support remote upgrade"
                        },
                        {
                            name: "Independent research and development, provide professional maintenance and technical support"
                        },
                    ]
                }, {
                    pic: "/device/public/feature4.png",
                    name: "Economic income",
                    infoList: [
                        {
                            name: "Small investment, long-term stable returns"
                        },
                        {
                            name: "Full support from the manufacturer"
                        },
                    ]
                }
            ],
            // 公共
            scenariosInfo: {
                titleChName: "Product Advantages",
                titleEnName: "适用范围"
            },
            purchaseInfo: {
                titleChName: "Worry-free after-sales guarantee for purchase",
                titleEnName: "购货无忧 售后保障"
            },
            purchaseList: [
                {
                    pic: "/device/public/purchase1.png",
                    name: "Follow up and guide the installation and follow-up services throughout the process"
                },
                {
                    pic: "/device/public/purchase1.png",
                    name: "Software and hardware systems are upgraded free of charge"
                }, {
                    pic: "/device/public/purchase1.png",
                    name: "Provide a complete set of copywriting materials for OEM customers"
                }, {
                    pic: "/device/public/purchase1.png",
                    name: "Enterprise wechat group one-to-one customer service tracking service"
                }, {
                    pic: "/device/public/purchase1.png",
                    name: "Assist in site operation"
                },
            ],
            display: {
                picList: [
                    {
                        title: "blue",
                        pic: "/device/charge/blueBig.png",
                        color: 'blue'
                    },
                    {
                        title: "white",
                        pic: "/device/charge/writeBig.png",
                        color: '#9F9F9F'
                    },
                    {
                        title: "orange",
                        pic: "/device/charge/orangeBig.png",
                        color: 'orange'
                    },
                ],
                pDisplayInfo: {
                    titleChName: "Product display",
                    titleEnName: "产品展示"
                },
                title: "Scenario installation diagram"
            }
        }

    }
}
export default info