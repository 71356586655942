import Nav from './nav';
import IndexPage from './indexPage';
import AboutUs from './aboutUs';
import CooperationCases from './cooperationCases';
import HardwareProducts from './hardwareProducts';
import InvestmentPromotion from './investmentPromotion';
import SoftwareSystem from './softwareSystem';
import Cooperate from './cooperate';
import Foot from './foot';
export default {
  Nav,
  IndexPage,
  AboutUs,
  CooperationCases,
  HardwareProducts,
  InvestmentPromotion,
  SoftwareSystem,
  Cooperate,
  Foot
};
