const scrolDisplay = {
    inserted: function (el, binding) {
        setTimeout(() => {
            let screenHeigt = document.documentElement.clientHeight
            let elTop = el.offsetTop
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (screenHeigt + scrollTop < elTop) {
                //初始化
                el.style.opacity = 0;
                el.style.transform = `translateY(100px)`;
                window.addEventListener('scroll', function () {
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                    if (screenHeigt + scrollTop >= elTop) {
                        el.style.transition = `1s`;
                        el.style.opacity = 1
                        el.style.transform = `translateY(0px)`;
                    }
                })
            }
        }, 50)

    }
}

module.exports = { scrolDisplay }